import React, { Component } from "react";
// import LandingMain from './LandingMain';
import AppRouter from "./AppRouter";
import "../style/css/master.css";
import "../style/css/masterResp.css";
import "../style/css/common.css";
import "../style/css/commonResp.css";

class App extends Component {
    renderWhereILeft() {
        return (
            <div className="dev">
                Where did I Leave it last <br />
                Socket has been connected - now show the message
            </div>
        );
    }

    render() {
        return (
            <div className="App">
                <AppRouter />
                {/* {this.renderWhereILeft()} */}
            </div>
        );
    }
}

export default App;
