import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { stateIsTrue } from "./";

const handlePermission = () => {
    /**
     * Render this as the first render in the component
     * this will redirect the user to login if the user is not authenticated
     */
    if (!stateIsTrue(localStorage.getItem("x-auth-token"))) {
        return <Redirect to="/login" />;
    }

    if (localStorage.getItem("token") !== "") {
        return null;
    }

    return <Redirect to="/login" />;
};

export { handlePermission };
