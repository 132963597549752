import React, { Component } from "react";
import { InputField, stateIsTrue } from "../";

let formDataObj = {};

class GenderFieldSet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validate_form: false,
            error: false,
            maleSelected: false,
            femaleSelected: false,
            othersValue: "",
        };
    }

    componentDidMount() {
        // console.log(formDataObj);
    }

    //helper
    onFieldUdate(obj, source = "input") {
        // console.log(obj);
        if (source === "input") {
            this.setState({
                maleSelected: false,
                femaleSelected: false,
            });
        }
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
            id: obj.id,
            // maleSelected: false,
            // femaleSelected: false
        };

        // console.log(formDataObj);
    }

    componentWillReceiveProps(newProps) {
        // console.log(newProps);
        if (stateIsTrue(newProps.forceValidation)) {
            this.validateAndRespond(this.state.value);
        }
    }

    validateAndRespond(value) {
        if (!formDataObj.gender || !formDataObj.gender.value) {
            formDataObj.gender = {
                error: true,
                id: "gender",
            };
            this.setState({ error: true });
        } else {
            this.setState({ error: false });
        }

        this.props.callBack(formDataObj.gender);
    }

    onMale() {
        const rv = {
            value: "Male",
            error: false,
            id: "gender",
        };

        if (this.state.maleSelected) {
            /** second click to disable the button */
            rv.value = "";
            rv.id = "gender";
            rv.error = true;
            this.setState({
                maleSelected: false,
                femaleSelected: false,
                error: true,
            });
        } else {
            this.setState({
                maleSelected: true,
                femaleSelected: false,
                error: false,
            });
        }

        this.onFieldUdate(rv, "button");
    }
    onFemale() {
        const rv = {
            value: "Female",
            error: false,
            id: "gender",
        };

        if (this.state.femaleSelected) {
            /** second click to disable the button */
            rv.value = "";
            rv.id = "gender";
            rv.error = true;
            this.setState({
                maleSelected: false,
                femaleSelected: false,
                error: true,
            });
        } else {
            this.setState({
                maleSelected: false,
                femaleSelected: true,
                error: false,
            });
        }

        this.onFieldUdate(rv, "button");
    }

    renderOthers() {
        const handleDisable = () => {
            if (this.state.femaleSelected || this.state.maleSelected) {
                return true;
            }

            return false;
        };

        return (
            <div className="fiedset">
                <InputField
                    id="gender"
                    placeHolder="Prefer to self-describe"
                    // required
                    forceValidation={this.state.validate_form}
                    callBack={this.onFieldUdate.bind(this)}
                    donotShowlabel={this.props.donotShowlabel}
                    disabled={handleDisable()}
                />
            </div>
        );
    }

    renderButtons() {
        const getClassNamemale = () => {
            if (this.state.maleSelected) {
                return "active";
            }
            return null;
        };
        const getClassNameFemale = () => {
            if (this.state.femaleSelected) {
                return "active";
            }
            return null;
        };

        return (
            <div className="fiedset">
                <div
                    className={`item male ${getClassNamemale()}`}
                    onClick={this.onMale.bind(this)}
                >
                    <div className="gIcon" />
                    <div className="label">Male</div>
                </div>
                <div
                    className={`item female ${getClassNameFemale()}`}
                    onClick={this.onFemale.bind(this)}
                >
                    <div className="gIcon" />
                    <div className="label">Female</div>
                </div>
            </div>
        );
    }

    renderContent() {
        return (
            <div className="gContent">
                {this.renderButtons()}
                {this.renderOthers()}
            </div>
        );
    }

    renderLabel() {
        return (
            <div className="title p1b">What best describes your gender?</div>
        );
    }

    renderError() {
        if (this.state.error) {
            return (
                <div className="error">
                    Please choose your gender or self-describe
                </div>
            );
        }
    }

    render() {
        return (
            <div id="genderFieldWrapperId" className="genderFieldWrapper">
                {this.renderLabel()}
                {this.renderContent()}
                {this.renderError()}
            </div>
        );
    }
}

export { GenderFieldSet };
