import React, { Component } from "react";
import {
    PhoneField,
    InputField,
    EmailField,
    TextAreaField,
    DropDownField,
    FormDataValidation,
    stateIsTrue,
    SpinnerLine,
    PostDataDirect,
    getDataFromServerResponseNonData,
    convertFormObjToPostObj,
    Checkbox,
    DatePickerDropDown,
    getYearMinus,
    ImageUploadWithSubmit,
} from "./common";
import { urls } from "./constant/urls";

const successIcon = require("../assets/icons/success.png");
const failedIcon = require("../assets/icons/failed.png");

class AddFilms extends Component {
    constructor(props) {
        super(props);
        this.formDataObj = {};
        this.state = {
            validate_form: false,
            submitingData: false,
            success: false,
            failed: false,
        };
    }

    /** lifecycle events */
    componentDidUpdate() {
        if (this.state.validate_form) {
            this.handleFormSubmit();
            this.setState({ validate_form: false });
        }
    }

    /** user events */
    onFieldUdate(obj) {
        // console.log(obj);
        this.formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        };
    }

    OnSubmit() {
        this.setState({
            validate_form: true,
            submitingData: true,
        });
    }

    /** helper methods */
    handleFormSubmit() {
        const hasError = FormDataValidation(this.formDataObj);
        if (!hasError) {
            /** validation passed, submit form */
            this.submitForm();
        } else {
            /** Validation failed, nothing to do,
             * the input field will show erriors by default */
            this.setState({ submitingData: false });
        }
    }

    submitForm() {
        const url = urls.dispenseFilm;
        console.log(url);
        const postObj = convertFormObjToPostObj(this.formDataObj);

        const callBackFucntion = (res) => {
            const result = getDataFromServerResponseNonData(res);
            if (result.success) {
                this.setState({ success: true });
            } else {
                this.setState({ failed: true });
            }

            this.setState({ submitingData: false });
        };
        // console.log(postObj);
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    }

    renderForm() {
        const renderSubmit = () => {
            if (this.state.submitingData) {
                return (
                    <div className="submit_wrapper">
                        <SpinnerLine />
                    </div>
                );
            }

            return (
                <div className="submit_wrapper">
                    <div className="button" onClick={this.OnSubmit.bind(this)}>
                        Submit
                    </div>
                </div>
            );
        };

        return (
            <div className="form_holder">
                <div className="fiedset">
                    <InputField
                        id="title"
                        placeHolder="Title"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                    />
                </div>
                <div className="fiedset">
                    <TextAreaField
                        id="description"
                        placeHolder="Description"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                    />
                </div>
                <div className="fiedset">
                    <InputField
                        id="youtubeId"
                        placeHolder="Youtube ID"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                    />
                </div>
                <div className="fiedset">
                    <Checkbox
                        callBack={this.onFieldUdate.bind(this)}
                        forceValidation={this.state.validate_form}
                        id="isBanner"
                        label="Should this be user in the banner sections"
                    />
                </div>

                <div className="fiedset">{renderSubmit()}</div>
            </div>
        );
    }

    renderSuccess() {
        if (this.state.success) {
            return (
                <div className="form_holder resultWrapper">
                    <div className="icon">
                        <img src={successIcon} alt="" />
                    </div>
                    Success!!
                </div>
            );
        }

        return null;
    }

    renderFailed() {
        if (this.state.failed) {
            return (
                <div className="form_holder resultWrapper">
                    <div className="icon">
                        <img src={failedIcon} alt="" />
                    </div>
                    Sorry Something when wrong
                </div>
            );
        }

        return null;
    }

    renderDecide() {
        if (this.state.success) return this.renderSuccess();
        if (this.state.failed) return this.renderFailed();

        return this.renderForm();
    }

    render() {
        return (
            <div className="addFilmsWrapper card shadow formWrapper">
                {this.renderDecide()}
            </div>
        );
    }
}

export default AddFilms;
