// import React from 'react';

// const basePath = "http://localhost:4000/";
const basePath = "https://api.alokeshetty.com/";

const urls = {
    /** auth */
    auth: `${basePath}api/auth/admin`,

    /** serve */
    films: `${basePath}api/serve/films`, // list for client ( thumbnailImage mandatory )
    filmsAdmin: `${basePath}api/serve/films/admin`, // list in admin section
    filmsTotal: `${basePath}api/serve/films/total`,
    filmsDoc: `${basePath}api/serve/films/doc`,

    /** dispense */
    dispenseFilm: `${basePath}api/dispense/film`,

    /** update */
    updateFilm: `${basePath}api/update/film`,

    /** file handlers */
    filmThumbnailUpload: `${basePath}api/file/film/thumbnail`,
    filmBannerUpload: `${basePath}api/file/film/banner`,

    /** delete */
    deleteFilmImage: `${basePath}api/delete/film/image`,
};

const imagePaths = {
    thumbnailUrl: `${basePath}images/films/thumb`,
    bannerUrl: `${basePath}images/films/banner`,
};

export { urls, imagePaths };
