/*  
        :::: NOTE :::::
        version 6
        This version got a major bug fix on 17-06-20
        The loop that was flatening the addray "makeSingleLevelArray", 
        was skipping if the value was a 0, ( in the case of minutes, or hours ),
        This was causing the final response to be on index lesser. 

    //== options array
       The component requires obj with a value in each index;

            // bare minimum required to work  
            strength: [
                {
                    value: "Easy"
                },
                {
                    value: "Intermediate"
                },
            ],

            // you can pass addional vaues that will be returned 
            strength: [
                {
                    id: "easy",
                    value: "Easy"
                },
                {
                    id: "intermediate",
                    value: "Intermediate"
                },
            ],
            
        ////////==== Labels
        There are two labels, 
            1) inline ( just appears before the value )
            2) slide up when a value is selected:

        [ hidePlaceHolderIfValue ] this prop will disable the first label 
        [ disableSlidingLabel ] this prop will disable the second label 


        //////====== disable a field
        {
            id: convertedValue.dayString,
            value: convertedValue.readableHuman1,
            dateFull: thisDate,
            disableThis = true
        }
        
    //== the jsx

    <DropDownField 
        options={options}
        required
        callBack={this.handleValue}
        icon={downIcon}
        placeHolder = "Hours"
        hidePlaceHolderIfValue
        presetToKey="0"
        forceValidation={this.state.validate_form}
        //disableSlidingLabel
        //skipNone
        noneText="Any Neighbourhood"
        // showOptionsOnLoad
        // onOutsideClickCallBack={this.props.onOutsideClickCallBack}
    />

    // presetToKey, use this to preset value ( used for editing )
    // hidePlaceHolderIfValue: When an option is selected, only the option will be shown and no place holder before
*/

import React, { Component } from "react";
import { stateIsTrue, validateFormData, convertDateToUnix } from "../";

import "../../../style/css/formStyle.css";

const downIcon = require("./support/down.png");

class DropDownField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showOptions: false,
            value: "",
            selectedKey: "",
            error: false,
            errorMessage: "",
            forceValidation: false,
            showLabel: false,
            presetUpdated: false,
            hideOptionProps: false,
            forceHideOptions: false,
            disableList: [],
            programmaticPlaceHolder: "",
            optionHidenDuetoOutsideClick: false,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        // this.presetOptionToKey();
        // console.log(this.props.options);
        this.checkPreset();
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentDidUpdate() {
        this.handlingReactBug();

        if (!this.state.showLabel) {
            if (this.state.value !== "") {
                this.setState({ showLabel: true });
            }
        }

        if (!this.state.presetUpdated) {
            this.checkPreset();
            this.setState({ presetUpdated: true });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    componentWillReceiveProps(newProps) {
        // console.log(newProps);
        if (this.state.forceValidation !== newProps.forceValidation) {
            this.setState({ forceValidation: newProps.forceValidation });
            this.validateAndRespond(this.state.value, this.state.selectedKey);
        } else {
            // console.log('forceValidate ignored because of the same value');
        }

        if (this.props.presetToKey !== newProps.presetToKey) {
            /** The presetToKey value has changes in the parent, so reset the drop down
             *  This could be, the parent is getting value from another source (calendar)
             *  and is trying to show that in this drop down. so the parent is resetting
             *  the options and seeting the new option as default
             */
            this.setState({ presetUpdated: false });
        }

        // this.handleHideOptionProps(newProps);
    }

    /** helper */
    handlingReactBug() {
        /** this it is a rect bug
         *  when there are two components, the selected value of an old component is getting
         *  passed into this the next component as value by default
         *
         */

        const checkIfValueIsInOptions = () => {
            let goodValue = false;
            const op = this.props.options;
            Object.keys(op).map((key, index) => {
                const thisValue = op[key]["value"];
                if (thisValue === this.state.value) {
                    /** correct value */
                    goodValue = true;
                }
            });

            if (!goodValue) {
                console.log(
                    `Value: "${this.state.value}" was provided as default value ( a react bug ). since this value is not in the options for this dropdown. resetting it to blank ''`
                );
                this.setState({ value: "" });
            }
        };

        if (stateIsTrue(this.state.value)) {
            checkIfValueIsInOptions();
        }
    }

    checkPreset() {
        if (stateIsTrue(this.props.presetToKey)) {
            if (stateIsTrue(this.props.options)) {
                // console.log('options are read to use');
                // console.log(this.props.options[this.props.presetToKey]['value']);

                const key = this.props.presetToKey;
                let rowOptions = this.props.options;
                const options = this.makeSingleLevelArray(rowOptions);
                // console.log(rowOptions, key);

                if (rowOptions[key]["disableThis"]) {
                    /** the preset key is a disabled value
                     *  so will not be presetting the value
                     */
                } else {
                    this.onSelectOption(key, options, this);
                }
            }
        } else {
            // console.log('no preset');
        }

        if (stateIsTrue(this.props.showOptionsOnLoad)) {
            this.setState({ showOptions: true });
        }
    }

    onShowOptions() {
        if (this.state.optionHidenDuetoOutsideClick) {
            this.setState({ optionHidenDuetoOutsideClick: false });
        } else {
            const showing = this.state.showOptions;
            let rv = true;
            if (showing) {
                rv = false;
                this.validateAndRespond(
                    this.state.value,
                    this.state.selectedKey
                );
            }
            this.setState({ showOptions: rv });

            this.informParentTheOptionsStatus();
        }
    }

    informParentTheOptionsStatus() {
        if (stateIsTrue(this.props.callBackWhenDropDownIsShowing)) {
            this.props.callBackWhenDropDownIsShowing();
        }
    }

    onNone() {
        this.setState({
            showOptions: false,
            value: "",
            selectedKey: "",
        });

        const rv = {
            value: "",
        };

        this.informParentTheOptionsStatus();

        this.validateAndRespond(rv, "");
    }

    onSelectOption(key, options, thisNow) {
        thisNow.setState({ value: options[key] });
        thisNow.setState({ selectedKey: key });
        thisNow.setState({ showOptions: false });

        this.informParentTheOptionsStatus();
        this.validateAndRespond(options[key], key);
    }

    validateAndRespond(value, key) {
        // console.trace('called');
        // console.log(value);
        const rv = {};
        // rv.value = value;
        rv.value = this.props.options[key];
        rv.error = false;
        rv.selectedKey = key;

        if (stateIsTrue(this.props.required) && this.props.required) {
            if (value === "") {
                this.setState({ error: true });
                this.setState({ errorMessage: "This Field is Required" });
                rv.error = true;
            } else {
                if (this.state.error) {
                    this.setState({ error: false });
                    this.setState({ errorMessage: "" });
                }
                rv.error = false;
            }
        }

        if (stateIsTrue(this.props.callBack)) {
            rv.id = convertDateToUnix(new Date());
            if (stateIsTrue(this.props.id)) {
                rv.id = this.props.id;
            }

            this.props.callBack(rv);
        }
    }

    //helper
    handleHideOptionProps(newProps) {
        /**
         *  Hide the option window from the parent
         *
         * -> use case
         * this is used for the calendar,
         *  In the calendar there is both drop down and calendar, so if the dropdown is open
         *  and the caledar is also clicked on then the drop down should close back. This is
         *  handled by this
         */
        if (stateIsTrue(this.props.parentCallTriggerToHideOptions)) {
            const oldValue = this.props.parentCallTriggerToHideOptions;
            const newValue = newProps.parentCallTriggerToHideOptions;

            if (oldValue != newValue) {
            }
        }
    }

    getIconClass() {
        let className = "icon_wrapper";
        const showing = this.state.showOptions;
        if (showing) {
            className = "icon_wrapper icon_up";
        }
        return className;
    }

    getClassName() {
        let className = `select_wrapper ${this.props.id}SelectWrapper`;
        if (this.state.error) {
            className = `${className} error`;
        }

        return className;
    }

    getErrorState() {
        let rv = false;
        if (
            stateIsTrue(this.props.errorData) &&
            stateIsTrue(this.props.errorData.data)
        ) {
            const obj = this.props.errorData.data;
            if (stateIsTrue(obj[this.props.fieldKey])) {
                rv = true;
            }
        }
        return rv;
    }

    makeSingleLevelArray(options) {
        const rv = [];
        const disableRv = [];
        Object.keys(options).map((key, index) => {
            // console.log(options);
            if (options[key]["value"] || options[key]["value"] === 0) {
                rv.push(options[key]["value"]);
            } else {
                console.log("value key is missing in the options obj");
            }
        });

        return rv;
    }

    getTheDisableList(options) {
        /** the list of options that should be disabled */
        const rv = [];
        Object.keys(options).map((key, index) => {
            // console.log(options[key]);
            if (options[key]["value"]) {
                if (options[key]["disableThis"]) {
                    // console.log('reached');
                    rv.push(options[key]);
                }
            }
        });

        // console.log(rv);

        return rv;
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        // console.log(this.wrapperRef );
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            // alert('You clicked outside of me!');

            this.setState({
                showOptions: false,
                optionHidenDuetoOutsideClick: true,
            });

            if (this.props.onOutsideClickCallBack)
                this.props.onOutsideClickCallBack();
        }
    }

    renderOptions() {
        let rowOptions = this.props.options;
        let options = [];
        let disabledOptions = [];
        if (typeof rowOptions[0] === "object") {
            // if the options are an object convert it to a single level array
            disabledOptions = this.getTheDisableList(rowOptions);
            options = this.makeSingleLevelArray(rowOptions);
            // console.log(disabledOptions, options);
        }

        const getDisabledClass = (item) => {
            let rv = false;
            Object.keys(disabledOptions).map((key, index) => {
                const disabledItem = disabledOptions[key];
                if (disabledItem.id === item.id) {
                    rv = true;
                }
            });

            return rv;
        };

        const renderSelectedRow = (index, key) => {
            if (getDisabledClass(rowOptions[key])) {
                return (
                    <div
                        className="option selected disableThis"
                        key={index}
                        // onClick={() => this.onSelectOption(key, options, this)}
                    >
                        {options[key]}
                    </div>
                );
            }

            return (
                <div
                    className="option selected"
                    key={index}
                    onClick={() => this.onSelectOption(key, options, this)}
                >
                    {options[key]}
                </div>
            );
        };

        const renderOptions = (index, key) => {
            if (getDisabledClass(rowOptions[key])) {
                return (
                    <div
                        className="option disableThis"
                        key={index}
                        // onClick={() => this.onSelectOption(key, options, this)}
                    >
                        {options[key]}
                    </div>
                );
            }

            return (
                <div
                    className={`option`}
                    key={index}
                    onClick={() => this.onSelectOption(key, options, this)}
                >
                    {options[key]}
                </div>
            );
        };

        const renderDecideOptions = (index, key) => {
            if (index === parseInt(this.state.selectedKey))
                return renderSelectedRow(index, key);
            return renderOptions(index, key);
        };

        const skipNone = () => {
            if (this.props.skipNone) return null;

            const getNone = () => {
                if (stateIsTrue(this.props.noneText))
                    return this.props.noneText;
                return "None";
            };

            return (
                <div
                    className="option noneField"
                    onClick={this.onNone.bind(this)}
                >
                    {getNone()}
                </div>
            );
        };

        if (this.state.showOptions) {
            return (
                <div className="option_wrapper" ref={this.setWrapperRef}>
                    {skipNone()}
                    <div className="options">
                        {Object.keys(options).map((key, index) => {
                            return renderDecideOptions(index, key);
                        })}
                    </div>
                </div>
            );
        }

        return null;
    }

    renderValue() {
        let placeHolder = "-- Select One --";
        if (stateIsTrue(this.props.placeHolder)) {
            placeHolder = this.props.placeHolder;
        }

        if (this.state.value === "") {
            return <div className="placeholder">{placeHolder}</div>;
        }

        if (stateIsTrue(this.props.hidePlaceHolderIfValue)) {
            return (
                <div className="selected_value">
                    <div className="value">{this.state.value}</div>
                </div>
            );
        }

        return (
            <div className="selected_value">
                <div className="label">{placeHolder}:</div>
                <div className="value">{this.state.value}</div>
            </div>
        );
    }

    renderIcon() {
        if (!stateIsTrue(this.props.icon)) {
            // return null;
            return (
                <div className={this.getIconClass()}>
                    <img src={downIcon} alt="v" />
                </div>
            );
        }

        return (
            <div className={this.getIconClass()}>
                <img src={this.props.icon} alt="v" />
            </div>
        );
    }

    renderInputField() {
        return (
            <div
                className="input_field"
                value="testing"
                onClick={this.onShowOptions.bind(this)}
            >
                <div className="value">{this.renderValue()}</div>

                {this.renderIcon()}
            </div>
        );
    }

    renderErrorField() {
        if (this.state.error) {
            return (
                <div className="error_wrapper animated pulse">
                    {this.state.errorMessage}
                </div>
            );
        }

        return null;
    }

    renderLabel() {
        if (stateIsTrue(this.props.disableSlidingLabel)) {
            return null;
        }

        if (stateIsTrue(this.state.showLabel)) {
            return (
                <div className="label_wrapper animated slideInUp">
                    {this.props.placeHolder}
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div className={this.getClassName()}>
                {this.renderLabel()}
                {this.renderInputField()}
                {this.renderOptions()}
                {this.renderErrorField()}
            </div>
        );
    }
}

export { DropDownField };
