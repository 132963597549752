import React, { Component } from "react";
import LoginForm from "./LoginForm";
import "../style/css/login.css";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateName: "",
        };
    }

    /** lifecycle events */

    /** user events */

    /** helper functions */

    /** renders */

    renterLoginForm() {
        return (
            <div className="centerBox">
                <div className="header">Login</div>
                <LoginForm />
            </div>
        );
    }

    render() {
        return (
            <div className="copperGrade">
                <div className="loginWrapper">{this.renterLoginForm()}</div>
            </div>
        );
    }
}

export default Login;
