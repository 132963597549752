/*
    <Accordian title="Title text over here" callBack={this.onTrigger}>
        body content as children
    </Accordian>
*/

import React, { Component } from "react";

class Accordian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
    }

    onTitle() {
        if (this.state.show) {
            this.setState({ show: false });
        } else {
            this.setState({ show: true });
        }

        if (this.props.callBack) {
            this.props.callBack();
        }
    }

    getHeadeeClassName() {
        let rv = "accordian_head";
        if (this.state.show) {
            rv = `${rv} active`;
        }

        return rv;
    }

    renderAccordian() {
        const renderBody = () => {
            if (this.state.show) {
                return (
                    <div className="accordian_body animated fadeIn">
                        {this.props.children}
                    </div>
                );
            }

            return null;
        };
        return (
            <div className="accordian_wrapper">
                <div
                    className={this.getHeadeeClassName()}
                    onClick={this.onTitle.bind(this)}
                >
                    {this.props.title}
                </div>
                {renderBody()}
            </div>
        );
    }

    render() {
        return <div>{this.renderAccordian()}</div>;
    }
}

export { Accordian };
