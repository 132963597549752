import React, { Component } from "react";
import { stateIsTrue } from "./HelperFunctions";
import { trimText } from "./";

class BodyTrimShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showingMore: false,
        };
    }

    componentDidUpdate() {
        // console.log(this.state);
    }

    onShowMore() {
        // console.log('show more');
        this.setState({ showingMore: true });
    }

    onShowLess() {
        this.setState({ showingMore: false });
    }

    renderBody() {
        if (stateIsTrue(this.props.body)) {
            const body = this.props.body;
            let length = 600;
            if (stateIsTrue(this.props.length)) {
                length = this.props.length;
            }

            const data = trimText(body, length);
            if (data.trimmed) {
                return (
                    <div className="text">
                        {data.string}
                        <div
                            className="showmore"
                            onClick={this.onShowMore.bind(this)}
                        >
                            Show More
                        </div>
                    </div>
                );
            }

            return <div className="text">{data.string}</div>;
        }

        return null;
    }

    renderFullBody() {
        if (stateIsTrue(this.props.body)) {
            const body = this.props.body;
            return (
                <div className="text">
                    {body}
                    <div
                        className="showmore"
                        onClick={this.onShowLess.bind(this)}
                    >
                        Show Less
                    </div>
                </div>
            );
        }

        return null;
    }

    decideRender() {
        if (this.state.showingMore) {
            return this.renderFullBody();
        }

        return this.renderBody();
    }

    render() {
        return <div>{this.decideRender()}</div>;
    }
}

export { BodyTrimShow };
