/*
    refreshCount

*/

import React, { Component } from "react";
import { PostDataDirect, stateIsTrue, getDataFromServerResponse } from ".";
import { SpinnerLine } from "./Spinner";

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalCountServerResponse: [],
            totalCount: 0,
            currentOffset: 10,
            limit: 10,
            refreshCount: "",
            refreshComponent: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log(nextProps);
        // console.log(prevState);
        if (prevState.refreshCount !== nextProps.refreshCount) {
            return {
                refreshCount: nextProps.refreshCount,
                refreshComponent: true,
            };
        }
        return null;
    }

    componentDidMount() {
        this.getTotalCount();
        this.init();
    }

    componentDidUpdate(prevProps, preState, snapShop) {
        if (prevProps !== this.props) {
            const oldCount = prevProps.refreshCount;
            const newCount = this.props.refreshCount;
            if (oldCount != newCount) {
                this.init();
            }
        }

        if (this.props.startTotal !== this.state.limit) {
            // console.log('update limit');
        }
        if (this.state.refreshComponent) {
            // console.log('refresh called');
        }
    }

    init() {
        this.setState({ currentOffset: this.props.startTotal });
        this.setState({ limit: this.props.startTotal });
    }

    onLoadTotal(res) {
        this.setState({ totalCountServerResponse: res });
        const resObj = getDataFromServerResponse(res);
        if (resObj.success) {
            const data = resObj.data;
            if (stateIsTrue(data)) {
                this.setState({ totalCount: data });
            }
        }
    }

    callBackParent(nOffset, nlimit) {
        // console.log('caleld');
        const obj = {
            offset: nOffset,
            limit: nlimit,
        };

        this.setState({ currentOffset: nOffset + nlimit });
        this.setState({ limit: nlimit });

        this.props.callBack(obj);
    }

    getTotalCount() {
        const postObj = {
            clientKeytoRefreshPagination: this.props.refreshCount,
        };
        PostDataDirect(
            this.props.countUrl,
            postObj,
            this.onLoadTotal.bind(this)
        );
    }

    renderNav() {
        const { totalCount, currentOffset, limit } = this.state;

        const renderPrev = () => {
            if (currentOffset > limit) {
                const pOffset = currentOffset - limit * 2;
                const plimit = limit;
                return (
                    <div
                        className="prev button"
                        onClick={() => this.callBackParent(pOffset, plimit)}
                    >
                        Prev
                    </div>
                );
            }

            return null;
        };

        const renderNext = () => {
            if (currentOffset < totalCount) {
                const nOffset = currentOffset;
                const nlimit = limit;

                return (
                    <div
                        className="next button"
                        onClick={() => this.callBackParent(nOffset, nlimit)}
                    >
                        Next
                    </div>
                );
            }

            return null;
        };

        return (
            <div className="nav_wrapper">
                {renderPrev()}
                {renderNext()}
            </div>
        );
    }

    renderBar() {
        if (
            stateIsTrue(this.state.totalCount) &&
            stateIsTrue(this.state.currentOffset)
        ) {
            return (
                <div className="pagination_warpper">
                    <div className="total_wrapper">
                        {this.state.currentOffset} / {this.state.totalCount}
                    </div>
                    {this.renderNav()}
                </div>
            );
        }

        return (
            <div className="pagination_warpper">
                <SpinnerLine />
            </div>
        );
    }

    render() {
        return this.renderBar();
    }
}

export { Pagination };
