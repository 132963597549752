import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../style/css/header.css";

const tempAvatar = require("../../assets/temp/avatar.jpg");

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMenu: false,
        };
    }

    /** lifecycle events */

    /** user events */
    onMenu() {
        this.setState({ showMenu: !this.state.showMenu });
    }

    onAddFilms() {
        this.setState({ showMenu: false });
        this.props.onAddFilms();
    }

    onFilms() {
        this.setState({ showMenu: false });
        this.props.onFilms();
    }

    /** helper functions */

    /** renders */
    renderMenu() {
        return (
            <div className="menuIcon">
                <div className="menu" onClick={this.onMenu.bind(this)} />
            </div>
        );
    }

    renderMenuContent() {
        if (!this.state.showMenu) return null;

        const renderFilmCMS = () => {
            return (
                <div className="menuSectionWrapper films">
                    <div className="button" onClick={this.onFilms.bind(this)}>
                        Films
                    </div>
                    <div
                        className="button"
                        onClick={this.onAddFilms.bind(this)}
                    >
                        Add Films
                    </div>
                </div>
            );
        };

        return (
            <div className="menuContentWrapper">
                <div className="menuContent">{renderFilmCMS()}</div>
            </div>
        );
    }

    render() {
        return (
            <div className="headerWrapper">
                <div className="headerHolder">
                    <div className="right">{this.renderMenu()}</div>
                </div>
                {this.renderMenuContent()}
            </div>
        );
    }
}

export { Header };
