import React, { Component } from "react";
import { stateIsTrue, convertDateToUnix } from "../";

import "../../../style/lib/animationStyles.css";

class PhoneField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            error: false,
            errorMessage: "",
            showLabel: false,
            valueLength: 6,
        };
    }

    componentDidMount() {
        if (stateIsTrue(this.props.maxLength)) {
            this.setState({ valueLength: this.props.maxLength });
        }
    }

    componentDidUpdate() {
        if (!this.state.showLabel) {
            if (this.state.value !== "") {
                if (!this.props.donotShowlabel) {
                    this.setState({ showLabel: true });
                }
            }
        }
    }

    componentWillReceiveProps(newProps) {
        // console.log(newProps);
        if (stateIsTrue(newProps.forceValidation)) {
            this.validateAndRespond(this.state.value, true);
        }
    }

    getClasses() {
        let classs = "input_field phone_field";
        if (stateIsTrue(this.props.className)) {
            classs = `${classs} ${this.props.className}`;
        }

        if (this.state.error) {
            classs = `${classs} error required`;
        }

        return classs;
    }

    getPlaceHolder() {
        if (stateIsTrue(this.props.placeHolder)) {
            return this.props.placeHolder;
        }

        return "Phone Number";
    }

    getId() {
        if (stateIsTrue(this.props.id)) {
            return this.props.id;
        }

        return new Date();
    }

    onBlurMethod() {
        this.validateAndRespond(this.state.value, true);
    }

    validateAndRespond(v, checkLength) {
        // update the value so force validation doesnt show default value as error
        let value = v;
        if (!value && stateIsTrue(this.props.defaultValue)) {
            value = this.props.defaultValue;
            this.setState({ value: value });
        }

        const rv = {};
        rv.value = value;
        rv.error = false;

        const checkPhoneNumber = () => {
            if (isNaN(value)) {
                this.setState({ error: true });
                this.setState({ errorMessage: "Please enter a valid number" });
                rv.error = true;
            } else {
                if (value.length < this.state.valueLength) {
                    this.setState({ error: true });
                    this.setState({
                        errorMessage: "Please enter a valid number",
                    });
                    rv.error = true;
                } else {
                    if (this.state.error) {
                        this.setState({ error: false });
                        this.setState({ errorMessage: "" });
                    }
                }

                rv.error = false;
            }
        };

        if (stateIsTrue(this.props.required)) {
            if (this.props.required) {
                if (value === "") {
                    this.setState({ error: true });
                    this.setState({
                        errorMessage: "Please enter a valid number",
                    });
                    rv.error = true;
                } else {
                    checkPhoneNumber();
                }
            } else {
                checkPhoneNumber();
            }
        } else {
            checkPhoneNumber();
        }

        if (checkLength && value !== "") {
            if (value.length < this.state.valueLength) {
                this.setState({ error: true });
                this.setState({ errorMessage: "Please enter a valid number" });
                rv.error = true;
            }
        }

        if (stateIsTrue(this.props.callBack)) {
            // rv.id ="id is not specified";
            rv.id = convertDateToUnix(new Date());
            if (stateIsTrue(this.props.id)) {
                rv.id = this.props.id;
            }
            this.props.callBack(rv);
        }
    }

    onChangeMethod(elem) {
        const value = elem.target.value;
        this.setState({ value: value });
        this.validateAndRespond(value);
    }

    renderError() {
        if (this.state.error) {
            return (
                <div className="error_wrapper animated fadeIn">
                    {this.state.errorMessage}
                </div>
            );
        }

        return null;
    }

    renderLabel() {
        if (stateIsTrue(this.state.showLabel)) {
            return (
                <div className="label_wrapper">{this.props.placeHolder}</div>
            );
        }

        return null;
    }

    render() {
        return (
            <div className={this.getClasses()}>
                {this.renderLabel()}
                <input
                    id={this.getId()}
                    type="text"
                    onChange={this.onChangeMethod.bind(this)}
                    onBlur={this.onBlurMethod.bind(this)}
                    placeholder={this.getPlaceHolder()}
                    defaultValue={this.props.defaultValue}
                />
                {this.renderError()}
            </div>
        );
    }
}

export { PhoneField };
