/*
    ::::: NOTE :::::
    version 6

    This version got a major bug fix on 17th may 20
    the bug: when finally provind the hour for submit the index ( which is one number lower )
    was picked up instead of the value ( which is what the user selects )

    :::::: HOW TO USE :::::
    <TimePicker 
        id='sunday_end'
        nthMinute={10}
        callBack={this.onFieldUdate.bind(this)}
        forceValidation={this.state.validate_form}
    />
*/

import React, { Component } from "react";
import { DropDownField, FormDataValidation } from "..";
import { stateIsTrue } from "../HelperFunctions";

const formDataObj = {};

class TimePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hour_value: "",
            minute_value: 0,
            format_value: "AM",
            submitClicked: false,
        };
    }

    componentWillReceiveProps(newProps, thisState) {
        if (stateIsTrue(newProps.forceValidation)) {
            this.onSubmit();
        }
    }

    onChangeHours(obj) {
        const hValue = obj.value ? obj.value.value : obj.value;
        this.setState({ hour_value: parseInt(hValue) });
        formDataObj[obj.id] = {
            id: obj.id,
            value: obj.value,
            error: obj.error,
        };
        // this.onSubmit();
    }
    onChangeMinutes(obj) {
        let selectedMinute = parseInt(obj.selectedKey) * this.props.nthMinute;
        console.log(selectedMinute, obj);
        if (!selectedMinute) selectedMinute = 0;
        this.setState({ minute_value: selectedMinute });
        formDataObj[obj.id] = {
            id: obj.id,
            value: obj.value,
            error: obj.error,
        };
        // this.onSubmit();
    }
    onChangeFormat(obj) {
        let format = "AM";
        if (parseInt(obj.selectedKey) === 1) {
            format = "PM";
        }

        this.setState({ format_value: format });
        formDataObj[obj.id] = {
            id: obj.id,
            value: obj.value,
            error: obj.error,
        };

        // this.onSubmit();
    }

    onSubmit() {
        const convertTo24 = () => {
            let hour = parseInt(this.state.hour_value);
            if (this.state.format_value === "PM") {
                if (hour !== 12) hour = hour + 12;
            } else {
                if (hour === 12) hour = 0;
            }

            return hour;
        };

        console.log(this.state.hour_value);

        this.setState({ submitClicked: true });
        const hasError = FormDataValidation(formDataObj);
        const rv = {
            id: this.props.id,
            value: {
                hours: this.state.hour_value,
                minutes: this.state.minute_value,
                format: this.state.format_value,
                readable: `${this.state.hour_value}:${this.state.minute_value} ${this.state.format_value}`,
                hourIn24: convertTo24(),
            },
            error: hasError,
        };
        // console.log(rv);

        this.props.callBack(rv);

        // if(!hasError) {
        //     // this.props.callBack(formDataObj)
        //     // this.props.callBack(rv)
        // } else {
        //     console.log('there are errors - do not submit');
        // }
    }

    getHours() {
        let rv = [];
        for (let i = 1; i < 13; i++) {
            const tRv = { value: i };
            rv.push(tRv);
        }

        return rv;
    }

    getMinutes() {
        let rv = [{ value: 0 }];
        let runningNo = 0;

        let interval = 0;
        if (stateIsTrue(this.props.nthMinute)) {
            interval = parseInt(this.props.nthMinute);
        }

        for (let i = 0; i < 60; i++) {
            // console.log(`${runningNo} - ${interval} - ${i}`);
            if (runningNo === interval) {
                const tRv = { value: i };
                rv.push(tRv);
                runningNo = 1;
            } else {
                runningNo++;
            }
        }
        console.log(rv);
        return rv;
    }

    getButtonText() {
        let rv = "Confirm";
        if (stateIsTrue(this.props.buttonText)) {
            rv = this.props.buttonText;
        }
        return rv;
    }

    render() {
        return (
            <div className="time_picker_wrapper">
                <div className="label_wrapper">{this.props.placeHolder}</div>
                <div className="time_fields">
                    <div className="timeOnly">
                        <div className="picker hours_wrapper">
                            <DropDownField
                                id="hours"
                                options={this.getHours()}
                                placeHolder="HR"
                                hidePlaceHolderIfValue
                                callBack={this.onChangeHours.bind(this)}
                                forceValidation={this.state.submitClicked}
                                required={this.props.required}
                                skipNone
                                // disableSlidingLabel
                            />
                        </div>
                        <div className="break">:</div>
                        <div className="picker minutes_wrapper">
                            <DropDownField
                                id="minutes"
                                options={this.getMinutes()}
                                placeHolder="MM"
                                hidePlaceHolderIfValue
                                callBack={this.onChangeMinutes.bind(this)}
                                forceValidation={this.state.submitClicked}
                                skipNone
                                // required
                                // disableSlidingLabel
                            />
                        </div>
                    </div>
                    <div className="picker ampm_wrapper">
                        <DropDownField
                            id="format"
                            options={[{ value: "Am" }, { value: "Pm" }]}
                            placeHolder="AM/PM"
                            // presetToKey="0"
                            hidePlaceHolderIfValue
                            callBack={this.onChangeFormat.bind(this)}
                            forceValidation={this.state.submitClicked}
                            required
                            skipNone
                            // disableSlidingLabel
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export { TimePicker };
