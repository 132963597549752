import React, { Component } from "react";
import { urls, imagePaths } from "./constant/urls";
import { CollectionList } from "./common";

class Films extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateName: "",
        };
    }

    /** lifecycle events */

    /** user events */

    /** helper functions */
    getListObj() {
        const listObj = [
            { label: "Title", key: "title" },
            { label: "Is Banner", key: "isBanner", type: "boolean" },
            // { label: "weight", key: "weight" },
        ];

        return listObj;
    }

    /** renders */
    renderList() {
        const editSkipFieldsObj = ["__v", "createdAt", "_id", "active"];
        const editRenderSpecObj = [
            {
                key: "isBanner",
                type: "boolean",
                tip: "if true, the film will be shown in the main banner",
            },
            {
                key: "thumbnailImage",
                type: "image",
                label: "Upload Thumbnail Image",
                maxWidth: 800,
                maxHeight: 400,
                UploadUrl: urls.filmThumbnailUpload,
                // docUpdateUrl: urls.updateFilm,
                displayImagePath: imagePaths.thumbnailUrl,
                deleteFileUrl: urls.deleteFilmImage,
                force: true, // if the field is not in the incoming data schema, still this will be shown
            },
            {
                key: "bannerImage",
                type: "image",
                label: "Upload Banner Image",
                maxWidth: 2000,
                maxHeight: 1000,
                UploadUrl: urls.filmBannerUpload,
                // docUpdateUrl: urls.updateFilm,
                displayImagePath: imagePaths.bannerUrl,
                deleteFileUrl: urls.deleteFilmImage,
                force: true, // if the field is not in the incoming data schema, still this will be shown
            },
            { key: "description", type: "textarea" },
            {
                key: "cloudFlareId",
                type: "input",
                force: true,
            },
            {
                key: "weight",
                type: "input",
                force: true,
            },
        ];

        return (
            <CollectionList
                listUrl={urls.filmsAdmin}
                totalCountUrl={urls.filmsTotal}
                objList={this.getListObj()}
                viewUrl={urls.filmsDoc}
                edit
                editSubmitUrl={urls.updateFilm}
                editSkipFieldsObj={editSkipFieldsObj}
                editRenderSpecObj={editRenderSpecObj}
                // delete // if the delete should be rejected from the server ( send response.reject:true, response.info:"message to show")
                // deleteUrl
                // pageTitleField="title" // this is shown in view and edit page, dot notation handled
                // filters={filters}

                // renderAdditionalData={functionName}
            />
        );
    }
    render() {
        return <div className="filmsCompWrapper">{this.renderList()}</div>;
    }
}

export default Films;
