/**
 * <TextAreaField
        id="message"
        placeHolder="Type a message"
        required
        forceValidation={this.state.validate_form}
        callBack={this.onFieldUdate.bind(this)}
        donotShowlabel
        autoHeight
        keyPressCallBack={this.onKeyPress.bind(this)} // when any key is pressed in the text area
        keyEnterPressCallBack={this.onEnterKeyPress.bind(this)} // when enter key is pressed in the textarea
    />

    /== autoHeight:
        This will extend the height of the text if more text is added
 */
import React, { Component } from "react";
import { stateIsTrue } from "../";

class TextAreaField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            error: false,
            errorMessage: "",
            showLabel: false,
            resetField: 0,
        };
    }

    /** lifecycle event */
    componentDidMount() {
        this.setState({ value: this.props.defaultValue });
        this.resetHeightForAutoHeight();
    }

    componentDidUpdate() {
        if (!this.state.showLabel) {
            if (this.state.value !== "") {
                this.setState({ showLabel: true });
            }
        }
    }

    componentWillReceiveProps(newProps) {
        // console.log(newProps);
        if (stateIsTrue(newProps.forceValidation)) {
            this.validateAndRespond(this.state.value);
        }

        if (newProps.resetField) {
            this.setState({ value: "" });
            this.resetHeightForAutoHeight();
        }
    }
    /** user events */
    onChangeMethod(elem) {
        const value = elem.target.value;
        this.setState({ value: value });

        this.validateAndRespond(value);

        if (this.props.autoHeight) {
            this.multilineTextarea.style.height = "auto";
            this.multilineTextarea.style.height =
                this.multilineTextarea.scrollHeight + "px";
        }
    }

    onKeyPressed(event) {
        if (this.props.keyPressCallBack) this.props.keyPressCallBack(event);
        if (this.props.keyEnterPressCallBack) {
            if (event.key === "Enter") this.props.keyEnterPressCallBack(event);
        }
    }

    /** helper methods */
    resetHeightForAutoHeight() {
        if (this.props.autoHeight) {
            if (this.multilineTextarea) {
                this.multilineTextarea.style.height = "auto";
            }
        }
    }

    getPlaceHolder() {
        if (stateIsTrue(this.props.placeHolder)) {
            return this.props.placeHolder;
        }

        return "Message";
    }

    getClasses() {
        let classs = "input_field text_area_field";
        if (stateIsTrue(this.props.className)) {
            classs = `${classs} ${this.props.className}`;
        }

        if (this.state.error) {
            classs = `${classs} error required`;
        }

        return classs;
    }

    validateAndRespond(value) {
        const rv = {};
        rv.value = value;
        rv.error = false;

        if (stateIsTrue(this.props.required) && this.props.required) {
            if (value === "") {
                this.setState({ error: true });
                this.setState({ errorMessage: "This Field Cannot be Blank" });
                rv.error = true;
            } else {
                if (this.state.error) {
                    this.setState({ error: false });
                    this.setState({ errorMessage: "" });
                }
                rv.error = false;
            }
        }

        if (stateIsTrue(this.props.callBack)) {
            rv.id = "id is not specified";
            if (stateIsTrue(this.props.id)) {
                rv.id = this.props.id;
            }
            this.props.callBack(rv);
        }
    }

    renderError() {
        if (this.state.error) {
            return (
                <div className="error_wrapper">{this.state.errorMessage}</div>
            );
        }

        return null;
    }

    renderLabel() {
        if (this.props.donotShowlabel) return null;

        if (stateIsTrue(this.state.showLabel)) {
            return (
                <div className="label_wrapper">{this.props.placeHolder}</div>
            );
        }

        return null;
    }

    render() {
        console.log(this.props.defaultValue);
        return (
            <div className={this.getClasses()}>
                {this.renderLabel()}
                <textarea
                    type="text"
                    value={this.state.value}
                    onChange={this.onChangeMethod.bind(this)}
                    placeholder={this.getPlaceHolder()}
                    ref={(ref) => (this.multilineTextarea = ref)}
                    onKeyPress={this.onKeyPressed.bind(this)}
                    defaultValue={this.props.defaultValue}
                />
                {this.renderError()}
            </div>
        );
    }
}

export { TextAreaField };
