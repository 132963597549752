import React, { Component } from "react";
import { handlePermission } from "./common";
import { Header } from "./boilerplates";
import AddFilms from "./AddFilms";
import Films from "./Films";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            showAddFilms: false,
            showFilms: false,
        };
    }

    /** lifecycle events */
    componentDidMount() {}

    /** user events */
    onAddFilms() {
        this.hideAll();
        this.setState({ showAddFilms: true });
    }

    onFilms() {
        this.hideAll();
        this.setState({ showFilms: true });
    }

    /** helper functions */
    hideAll() {
        this.setState({
            showFilms: false,
            showAddFilms: false,
        });
    }

    /** renders */

    renderDecide() {
        // if(!this.state.authenticated)
    }

    renderAddFilms() {
        if (!this.state.showAddFilms) return null;
        return <AddFilms />;
    }

    renderFilms() {
        if (!this.state.showFilms) return null;
        return <Films />;
    }
    render() {
        return (
            <div className="page_content_wrapper coolBlue">
                {handlePermission()}

                {this.renderAddFilms()}
                {this.renderFilms()}

                <Header
                    onAddFilms={this.onAddFilms.bind(this)}
                    onFilms={this.onFilms.bind(this)}
                />
            </div>
        );
    }
}

export default Dashboard;
