const GetData = (url, dispatch, reducer) => {
    /*
      GetData(url, dispatch, SERVICES_GET_SERVICE_VIEW);
    */

    fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
        .then((response) => response.json())
        .then((responseJson) => {
            if (reducer === false) {
                return responseJson;
            }
            dispatch({
                type: reducer,
                payload: responseJson,
            });
        })
        .catch((error) => {
            console.error(error);
        });
};

const PostData = (url, dispatch, postObj, reducer) => {
    /* caller
      export const ActionServerOpenWorkLog = (postObj) => {
          const url = urls.workStatusLog;
          return (dispatch) => {
              PostData(url, dispatch, postObj, WORKLOG_SEND_DATA_TO_SERVER);
          };
      };
    */

    fetch(url, {
        method: "POST",
        body: JSON.stringify(postObj),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "x-auth-token": localStorage.getItem("token"),
        },
    })
        .then((response) => response.json())
        .then((json) => {
            // console.log(json);
            if (reducer === false) {
                return json;
            }
            dispatch({
                type: reducer,
                payload: json,
            });
        })
        .catch((error) => {
            console.error(error);
        });
};

const PostFileRedux = (url, dispatch, postObj, reducer) => {
    /* caller
      export const ActionServerOpenWorkLog = (postObj) => {
          const url = urls.workStatusLog;
          return (dispatch) => {
              PostData(url, dispatch, postObj, WORKLOG_SEND_DATA_TO_SERVER);
          };
      };
    */

    fetch(url, {
        method: "POST",
        body: postObj,
        headers: {
            // 'Content-type': 'application/json; charset=UTF-8',
            "x-auth-token": localStorage.getItem("token"),
        },
    })
        .then((response) => response.json())
        .then((json) => {
            // console.log(json);
            if (reducer === false) {
                return json;
            }
            dispatch({
                type: reducer,
                payload: json,
            });
        })
        .catch((error) => {
            console.error(error);
        });
};

const PostFileDirect = (url, postObj, callBack) => {
    //NOTE::  uploadFileToServer (better error handling)
    /*
      Wroking module was build first for ovum 
      /////===== CALLER
        const file = formDataObj.profile.value;
        const url = urls.doctorDispense;
        const data = new FormData();
        data.append('file', file);

        const callBack = (res) => {
            console.log(res);
        }

        PostFileDirect(url, data, callBack.bind(this))
    */

    fetch(url, {
        method: "POST",
        body: postObj,
        headers: {
            // 'Content-type': 'application/json; charset=UTF-8',
            "x-auth-token": localStorage.getItem("token"),
        },
    })
        .then((response) => response.json())
        .then((json) => {
            // console.log(json);
            callBack(json);
        })
        .catch((error) => {
            console.error(error);
        });
};

const PostDataCustomCallBacks = (
    url,
    postObj,
    successFunction,
    failuerFunction
) => {
    /*
    CALLER:
    return (dispatch) => {
      dispatch({type: AUTH_USER});
      // console.log('auth called');
      const postObj = {
        employee_id: empId,
        password: pass,
      };
      
      const successFunction = (response) => {
        loginUserSuccess(dispatch, response);   
      };

      const failureFunction = () => {
        dispatch({type: LOGIN_USER_FAIL});
      };

      PostDataCustomCallBacks(urls.authUser, postObj, successFunction, failureFunction);
    };
  */

    fetch(url, {
        method: "POST",
        body: JSON.stringify(postObj),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "x-auth-token": localStorage.getItem("token"),
        },
    })
        .then((response) => response.json())
        .then((json) => {
            const response = scrapeResponse(json);
            if (response.success === 1) {
                successFunction(json);
            } else {
                console.log(response);
                failuerFunction();
            }
        })
        .catch((error) => {
            fetch(url, {
                method: "POST",
                body: JSON.stringify(postObj),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
                .then((res) => {
                    return res.text();
                })
                .then((fileRes) => {
                    const rv = [];
                    rv.component_response.error.push(fileRes);
                    rv.component_response.error.push(error);
                    rv.component_response.success = 0;
                    console.log(rv);
                });
            console.error(error);
        });
};

const PostDataDirect = (url, postObj, callBack, debug = false) => {
    /* caller
        const callBackFucntion = (res) => {
            console.log(res);
        }
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    */

    const token = localStorage.getItem("x-auth-token");
    if (debug) console.log("--<><>-- post data --token: ", token);

    fetch(url, {
        method: "POST",
        body: JSON.stringify(postObj),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "x-auth-token": token,
        },
    })
        .then((response) => response.json())
        .then((json) => {
            // console.log(json);
            callBack(json);
            return json;
        })
        .catch((error) => {
            console.error(error);
        });
};

const uploadFileToServer = (url, data, callBackFunc) => {
    /*
      Wroking module tested in ovum
      /////===== CALLER
        const file = formDataObj.profile.value;
        const url = urls.doctorDispense;
        const data = new FormData();
        data.append('file', file);

        const callBack = (res) => {
            console.log(res);
        }

        uploadFileToServer(url, data, callBack.bind(this))
    */

    const rv = [];
    rv.component_response = [];
    rv.component_response.info = [];
    rv.component_response.error = [];
    rv.component_response.success = 0;
    rv.server_response = [];

    fetch(url, {
        method: "post",
        body: data,
        // mode: "no-cors",
        headers: {
            "x-auth-token": localStorage.getItem("token"),
        },
    })
        .then((res) => {
            if (res.status !== 200) {
                rv.component_response.error.push(
                    "server could not process the request, the URL may not exists. The response is bellow:"
                );
                rv.component_response.error.push(res);
                return "server_error";
            }
            return res.json();
        })
        .then((serverRes) => {
            if (serverRes !== "server_error") {
                // object is json
                rv.component_response.info.push(serverRes);
                rv.component_response.success = 1;
                rv.server_response = serverRes;
                callBackFunc(rv);
            }
        })
        .catch((error) => {
            // console.log(error);
            rv.component_response.info.push(error);
            rv.component_response.info.push(
                "The URL could be wrong - the url received is: url = " + url
            );
            rv.component_response.info.push(
                "Alternatively The response may not be a json object, you can view the response bellow"
            );
            // fetch the data again to get the error text from the server
            fetch(url, {
                method: "post",
                body: data,
            })
                .then((res) => {
                    return res.text();
                })
                .then((fileRes) => {
                    rv.component_response.error.push(fileRes);
                    rv.component_response.error.push(error);
                    rv.component_response.success = 0;
                    callBackFunc(rv);
                });

            callBackFunc(rv);
        });
};

const uploadFileToServerRedux = (url, dispatch, data, reducer) => {
    /*
    Wroking module tested in ovum
    /////===== CALLER
      const file = formDataObj.profile.value;
      const data = new FormData();
      data.append('file', file);
      this.props.ActionUploadDoctorImage(data)

    ///// in action
      return (dispatch) => {
        const url = urls.doctorDispense;        
        uploadFileToServerRedux(url, dispatch, postObj, SUBMIT_DOC_RES);
      };
  */

    const rv = [];
    rv.component_response = [];
    rv.component_response.info = [];
    rv.component_response.error = [];
    rv.component_response.success = 0;
    rv.server_response = [];

    const callBackFunc = (res) => {
        // console.log(json);
        if (reducer === false) {
            return res;
        }
        dispatch({
            type: reducer,
            payload: res,
        });
    };

    fetch(url, {
        method: "post",
        // mode: "no-cors",
        body: data,
        headers: {
            "x-auth-token": localStorage.getItem("token"),
            // 'Content-type': 'application/json; charset=UTF-8',
        },
    })
        .then((res) => {
            if (res.status !== 200) {
                rv.component_response.error.push(
                    "server could not process the request, the URL may not exists. The response is bellow:"
                );
                rv.component_response.error.push(res);
                return "server_error";
            }
            return res.json();
        })
        .then((serverRes) => {
            if (serverRes !== "server_error") {
                // object is json
                rv.component_response.info.push(serverRes);
                rv.component_response.success = 1;
                rv.server_response = serverRes;
                callBackFunc(rv);
            }
        })
        .catch((error) => {
            // console.log(error);
            rv.component_response.info.push(error);
            rv.component_response.info.push(
                "The URL could be wrong - the url received is: url = " + url
            );
            rv.component_response.info.push(
                "Alternatively The response may not be a json object, you can view the response bellow"
            );
            // fetch the data again to get the error text from the server
            fetch(url, {
                method: "post",
                body: data,
            })
                .then((res) => {
                    return res.text();
                })
                .then((fileRes) => {
                    rv.component_response.error.push(fileRes);
                    rv.component_response.error.push(error);
                    rv.component_response.success = 0;
                    callBackFunc(rv);
                });

            callBackFunc(rv);
        });
};

const getDataUploadFile = (file, url, postValue, debug = true) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", file, file.name);
        Object.keys(postValue).map((key, index) => {
            const item = postValue[key];
            formData.append(key, item);
        });

        const req = new XMLHttpRequest();

        req.onreadystatechange = function () {
            if (this.readyState == 4) {
                const response = this.responseText;
                const rtResponse = JSON.parse(response);

                if (this.status == 200) {
                    resolve(rtResponse);
                } else {
                    reject(rtResponse);
                }
            }
        };

        req.open("POST", url);
        req.setRequestHeader(
            "x-auth-token",
            localStorage.getItem("x-auth-token")
        );
        req.send(formData);
    });
};

const scrapeResponse = (obj) => {
    // console.log(obj);
    const rv = [];
    rv.success = 0;
    rv.log = [];
    if (obj) {
        if (obj.response) {
            rv.obj = obj.response;
            if (obj.response.success !== "undefined") {
                rv.success = obj.response.success;
            } else {
                rv.log.push(
                    "cannot find success key in response from the server, hence set to default of 0"
                );
            }
        } else {
            rv.obj = obj;
            rv.log.push(
                "the response key is missing in the response received from the server"
            );
        }
    } else {
        rv.obj = obj;
        rv.log.push("Reponse onbject is undefined or missing");
    }

    return rv;
};

export {
    GetData,
    PostData,
    scrapeResponse,
    PostDataCustomCallBacks,
    uploadFileToServer,
    uploadFileToServerRedux,
    PostDataDirect,
    getDataUploadFile,
};
