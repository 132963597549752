import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./Login";
import DashBoard from "./Dashboard";
import AddFilms from "./AddFilms";
// import My404 from './My404';

class AppRouter extends Component {
    componentDidMount() {
        const isMobile = window.innerWidth <= 500;
        if (isMobile) {
            let sPath = window.location.href;
            sPath = sPath.split("/");
            if (sPath[3] !== "dev_error") {
                // window.location.href = '/dev_error';
            }
        } else {
            console.log("not mobile");
        }
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/" exact component={DashBoard} />
                    <Route path="/dashboard" exact component={DashBoard} />
                    <Route path="/login" exact component={Login} />

                    <Route path="/add/films" exact component={AddFilms} />
                </Switch>
            </Router>
        );
    }
}

export default AppRouter;
