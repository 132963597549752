/*
    <AutoCompleteDb
        id="intitution"
        placeHolder="College name"
        required
        forceValidation={this.state.validate_form}
        callBack={this.onFieldUdate.bind(this)}
        field="name"
        addAnoterField={[
            ['city',0,'name'],
            ['city',0,'state']
        ]}
        searchUrl={urls.institutionSearch}
        // donotShowlabel
    />

    //addAnoterField
    each index: ['city',0,'name'] = one field ( the sub indexes should be specified only if you want to take data from an object)
    eg for a sinfle field: 
    addAnoterField={[
        ['type']
    ]}

    //when submiting fetch this way
    formDataToPost.institutionId = formDataObj.institution.value._id;
*/

import React, { Component } from "react";

import {
    DropDownField,
    InputField,
    PostDataDirect,
    stateIsTrue,
    getDataFromServerResponse,
} from "../";
import "../../../style/css/formStyle.css";

// const downIcon = require('./support/down.png')
const autocompleteIcon = require("./support/autoCompleteIcon.png");
const autocompleteHoverIcon = require("./support/autoCompleteIconHover.png");
const remove = require("./support/remove.png");

let formDataObj = {};

class AutoCompleteDb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: 3,
            field: "name",
            options: [],
            showOptions: false,
            inputResponse: [],
            selectedOptionValue: "",
            switchInput: false,
            updateInput: false,
            setDefault: true,
            stateHover: false,
        };
    }

    componentDidMount() {
        if (stateIsTrue(this.props.field)) {
            if (stateIsTrue(this.props.addAnoterField)) {
                this.setState({ field: "AutoCompleteDbValue7878" });
            } else {
                this.setState({ field: this.props.field });
            }
        }
    }

    componentWillReceiveProps(newProps, oldProps) {
        if (stateIsTrue(newProps.forceValidation)) {
            this.setState({ updateInput: true });
        }

        if (stateIsTrue(newProps.defaultValue)) {
            if (this.state.setDefault) {
                const dValue = newProps.defaultValue;
                this.onClickOption(dValue);
                this.setState({
                    setDefault: false,
                });
            }
        }
    }

    //user events
    onMouseOverInput() {
        this.setState({ stateHover: true });
    }

    //helper
    handleAddonFields(data) {
        let rv = data;
        if (stateIsTrue(this.props.addAnoterField)) {
            let newData = [];
            Object.keys(data).map((key, index) => {
                const dataItem = data[key];
                let newValue = dataItem[this.props.field];
                const additionalFieldList = this.props.addAnoterField;
                Object.keys(additionalFieldList).map((key, index) => {
                    const fieldItem = additionalFieldList[key];
                    let fieldValue = "";
                    Object.keys(fieldItem).map((key, index) => {
                        if (fieldValue === "") {
                            fieldValue = dataItem[fieldItem[key]];
                        } else {
                            fieldValue = fieldValue[fieldItem[key]];
                        }
                    });
                    newValue = `${newValue} ${fieldValue}`;
                });

                const newRow = dataItem;
                newRow.AutoCompleteDbValue7878 = newValue;
                newData.push(newRow);
            });

            rv = newData;
        }

        return rv;
    }

    fetchDataForString(value) {
        if (!stateIsTrue(this.props.searchUrl)) return null;

        const callBackFucntion = (res) => {
            const resRes = getDataFromServerResponse(res);
            if (resRes.success) {
                const data = this.handleAddonFields(resRes.data);
                this.setState({ options: data });
                this.setState({ showOptions: true });
            } else {
                this.setState({ options: [] });
                this.setState({ showOptions: false });
            }
        };
        const postObj = {
            string: value,
        };
        const url = this.props.searchUrl;
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    }

    onFieldUdate(obj) {
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        };

        this.setState({ updateInput: false });
        this.setState({ inputResponse: obj });

        if (!obj.value) {
            this.setState({ showOptions: false });
            this.props.callBack(obj);
        }

        if (stateIsTrue(this.props.callBackForFieldChange)) {
            this.props.callBackForFieldChange(obj);
        }

        this.fetchForData(obj);
    }

    fetchForData(obj) {
        if (!obj.value) return null;

        // const res = this.matchString(obj.value);
        this.fetchDataForString(obj.value);
        // if(res.length > 0) {
        //     this.setState({options: res})
        //     this.setState({showOptions: true})
        // }
    }

    onClickOption(elem) {
        // console.log(elem);
        const { error } = this.state.inputResponse;
        const obj = {
            value: elem,
            id: this.props.id,
            error: error,
        };
        const selectedValue = elem[this.state.field];
        this.setState({ selectedOptionValue: selectedValue });
        this.setState({ showOptions: false });
        this.setState({ switchInput: true });
        this.props.callBack(obj);
    }

    onProxyInputClick() {
        this.setState({ switchInput: false });
        if (this.props.removeCallBack) this.props.removeCallBack();
    }

    renderOptions() {
        let options = this.state.options;

        if (this.state.showOptions) {
            return (
                <div className="option_wrapper">
                    <div className="options">
                        {Object.keys(options).map((key, index) => {
                            return (
                                <div
                                    className="option"
                                    key={index}
                                    onClick={this.onClickOption.bind(
                                        this,
                                        options[key]
                                    )}
                                >
                                    {options[key][this.state.field]}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return null;
    }

    renderInput() {
        if (this.state.switchInput) {
            return (
                <div
                    className="input_field input_field_proxy"
                    onClick={this.onProxyInputClick.bind(this)}
                >
                    <div className="input">
                        <div className="text">
                            {this.state.selectedOptionValue}
                        </div>
                        <div
                            className="autocomplete_icon"
                            // onClick={this.onProxyInputClick.bind(this)}
                        >
                            <img src={remove} alt="Edit" />
                        </div>
                    </div>
                </div>
            );
        }

        const getSearchIcon = () => {
            if (this.state.stateHover) return autocompleteHoverIcon;
            return autocompleteIcon;
        };

        return (
            <div
                className="inputFieldWrapper"
                // onMouseOver={this.onMouseOverInput()}
            >
                <InputField
                    id={this.props.id}
                    placeHolder={this.props.placeHolder}
                    required={this.props.required}
                    forceValidation={this.state.updateInput}
                    callBack={this.onFieldUdate.bind(this)}
                    defaultValue=""
                    donotShowlabel={this.props.donotShowlabel}
                />
                <div
                    className="autocomplete_icon"
                    onClick={this.onProxyInputClick.bind(this)}
                >
                    {/* <img 
                        src={getSearchIcon()}
                        alt="Edit"
                    /> */}
                    <div className="autoCompleteIcon" />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="auto_complete_wrapper">
                {this.renderInput()}
                {this.renderOptions()}
            </div>
        );
    }
}

const optionObj = ["somde", "sdsdsd"];

export { AutoCompleteDb };
