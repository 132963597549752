import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    PhoneField,
    InputField,
    EmailField,
    TextAreaField,
    DropDownField,
    FormDataValidation,
    stateIsTrue,
    SpinnerLine,
    PostDataDirect,
    getDataFromServerResponseNonData,
    convertFormObjToPostObj,
    DatePickerDropDown,
    getYearMinus,
    ImageUploadWithSubmit,
} from "./common";
import { urls } from "./constant/urls";

const successIcon = require("../assets/icons/success.png");
const failedIcon = require("../assets/icons/failed.png");

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.formDataObj = {};
        this.state = {
            validate_form: false,
            submitingData: false,
            success: false,
            failed: false,
            showPasswordForm: false,
            showPhoneForm: true,
            proceedWithApp: false,
        };
    }

    /** lifecycle events */
    componentDidUpdate() {
        if (this.state.validate_form) {
            this.handleFormSubmit();
            this.setState({ validate_form: false });
        }
    }

    /** user events */
    onFieldUdate(obj) {
        // console.log(obj);
        this.formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        };
    }

    OnSubmit() {
        this.setState({
            validate_form: true,
            submitingData: true,
        });
    }

    OnGetOtp() {
        this.setState({
            validate_form: true,
        });
    }

    onRetry() {
        this.setState({
            validate_form: false,
            submitingData: false,
            success: false,
            failed: false,
            showPasswordForm: false,
            showPhoneForm: true,
        });
    }

    /** helper methods */
    handleFormSubmit() {
        const hasError = FormDataValidation(this.formDataObj);
        if (!hasError) {
            /** validation passed, submit form */
            if (this.state.showPhoneForm) {
                this.setState({
                    showPhoneForm: false,
                    showPasswordForm: true,
                });
            } else {
                this.submitForm();
            }
        } else {
            /** Validation failed, nothing to do,
             * the input field will show erriors by default */
            this.setState({ submitingData: false });
        }
    }

    setUpLocalStorage(result) {
        localStorage.setItem("x-auth-token", result.res.token);
    }
    submitForm() {
        const url = urls.auth;
        const postObj = convertFormObjToPostObj(this.formDataObj);

        const callBackFucntion = (res) => {
            const result = getDataFromServerResponseNonData(res);
            if (result.success) {
                this.setUpLocalStorage(result);
                this.setState({ success: true, proceedWithApp: true });
                // this.props.successCallback();
            } else {
                this.setState({ failed: true });
            }

            this.setState({ submitingData: false });
        };
        // console.log(postObj);
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    }

    renderForm() {
        if (!this.state.showPhoneForm) return null;

        const renderSubmit = () => {
            if (this.state.submitingData) {
                return (
                    <div className="submit_wrapper">
                        <SpinnerLine />
                    </div>
                );
            }

            return (
                <div className="submit_wrapper">
                    <div
                        className="button buttonRev"
                        onClick={this.OnGetOtp.bind(this)}
                    >
                        Proceed
                    </div>
                </div>
            );
        };

        return (
            <div className="form_holder">
                <div className="fiedset">
                    <PhoneField
                        id="phone"
                        placeHolder="phone"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                        donotShowlabel
                    />
                </div>

                {renderSubmit()}
            </div>
        );
    }

    renderPasswordForm() {
        if (!this.state.showPasswordForm) return null;

        const renderSubmit = () => {
            if (this.state.submitingData) {
                return (
                    <div className="submit_wrapper">
                        <SpinnerLine />
                    </div>
                );
            }

            return (
                <div className="submit_wrapper">
                    <div
                        className="button buttonRev"
                        onClick={this.OnSubmit.bind(this)}
                    >
                        Submit
                    </div>
                </div>
            );
        };

        return (
            <div className="form_holder">
                <div className="fiedset">
                    <InputField
                        id="password"
                        placeHolder="Password"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                        donotShowlabel
                    />
                </div>

                {renderSubmit()}
            </div>
        );
    }

    renderSuccess() {
        console.log("rached");
        return <Redirect to="/" />;

        if (this.state.success) {
            return (
                <div className="form_holder resultWrapper">
                    <div className="icon">
                        <img src={successIcon} alt="" />
                    </div>
                    Success!! <br />
                    <div className="paddingAllArround">
                        Give us a moment <br />
                        to set up your account
                    </div>
                </div>
            );
        }

        return null;
    }

    renderFailed() {
        if (this.state.failed) {
            return (
                <div className="form_holder resultWrapper">
                    <div className="icon">
                        <img src={failedIcon} alt="" />
                    </div>
                    Umm, are you sure, <br /> you have the right access?
                    <div
                        className="button buttonRev"
                        onClick={this.onRetry.bind(this)}
                    >
                        Try again
                    </div>
                </div>
            );
        }

        return null;
    }

    renderRedirect() {
        // return <Redirect to="/dashboard" />;
    }

    renderDecide() {
        // if (this.state.proceedWithApp) return this.renderRedirect();
        if (this.state.success) return this.renderSuccess();
        if (this.state.failed) return this.renderFailed();
        if (this.state.showPasswordForm) return this.renderPasswordForm();
        return this.renderForm();
    }

    render() {
        return <div className="formWrapper">{this.renderDecide()}</div>;
    }
}

export default LoginForm;
