const whichWayToGoWithImage = ({ img, window = true, element }) => {
    const rv = [];
    rv.style = [];
    const imageWidth = img.width;
    const imageHeight = img.height;
    let windowWidth = "";
    let windowheight = "";
    let windowRatio = "";

    if (window) {
        windowWidth = window.innerWidth;
        windowheight = window.innerHeight;
        windowRatio = windowWidth / windowheight;
    } else {
        console.log(
            "you have made window to false, but handing an element is not yer writtern in this functions"
        );
        return rv;
    }

    const parentRatio = windowRatio;
    const imageRatio = imageWidth / imageHeight;

    if (imageRatio > parentRatio) {
        //manipulate image height
        rv.style.height = windowheight;
        rv.style.width = "auto";
    } else {
        //manupilare image width
        rv.style.height = "auto";
        rv.style.width = windowWidth;
    }

    return rv;
};

const makeStringShort = (text, start = 0, length = 90) => {
    return text.substring(start, length) + " ....";
};

const inArray = (needle, heystack) => {
    let rv = false;
    if (heystack.indexOf(needle) > -1) {
        rv = true;
    }

    return rv;
};

const rowFromArray = (needle, heystack, key) => {
    for (let i = 0; i < heystack.length; i++) {
        const elem = heystack[i];
        if (elem[key] == needle) {
            return elem;
        }
    }

    return null;
};

const deleterRowFromArray = (needle, heystack, key) => {
    let rv = [];
    for (let i = 0; i < heystack.length; i++) {
        const elem = heystack[i];
        if (elem[key] != needle) {
            rv.push(elem);
        }
    }

    return rv;
};

const stateIsTrue = (key) => {
    let rv = false;
    if (key && key !== "") {
        if (typeof key === "object") {
            if (Object.keys(key).length > 0) {
                rv = true;
            }
        } else {
            rv = true;
        }
    }

    return rv;
};

const urlFriendly = (string) => {
    let rv = string.replace(" ", "-");
    rv = rv.replace("/", "-");
    return rv;
};

const isObject = (path) => {
    // console.log(path);
    const pathObj = path.split(".");
    // console.log(pathObj);
};

const isEven = (n) => {
    return n % 2 == 0;
};

const trimText = (thisString, maxLength = 200) => {
    //will trim to words
    const rv = {};
    rv.trimmed = false;
    rv.string = "";
    let trimmedString = thisString.substr(0, maxLength);
    trimmedString = trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );

    if (thisString !== trimmedString) {
        trimmedString = `${trimmedString}...`;
        rv.trimmed = true;
    }

    rv.string = trimmedString;
    return rv;
};

const dataSuccess = (props) => {
    let rv = false;
    if (
        stateIsTrue(this.props.data) &&
        stateIsTrue(this.props.data.response) &&
        this.props.data.response.success === 1 &&
        stateIsTrue(this.props.data.response.data)
    ) {
        rv = true;
    } else {
        rv = false;
    }
};

const getDataFromServerResponse = (data) => {
    /*
        NOTE: if there is no valid data key in response, the response is false

        const data = getDataFromServerResponse(this.props);
        if(data.success) {
            
        }
    */
    const rv = {};
    rv.success = false;

    if (
        stateIsTrue(data) &&
        stateIsTrue(data.response) &&
        data.response.success === 1 &&
        stateIsTrue(data.response.data)
    ) {
        rv.success = true;
        rv.data = data.response.data;
    }

    return rv;
};

const getDataFromServerResponseNonData = (data) => {
    /*
        NOTE:  will be success if the response.success === 1, 
        It will not check if the data key exists

        const data = getDataFromServerResponse(this.props);
        if(data.success) {
            
        }
    */
    const rv = {};
    rv.success = false;

    if (
        stateIsTrue(data) &&
        stateIsTrue(data.response) &&
        data.response.success === 1
    ) {
        rv.success = true;
        rv.data = data.response.data;
        rv.res = data.response;
    }

    return rv;
};

const count = (array) => {
    let total = 0;
    Object.keys(array).map((key, index) => {
        total++;
        return null;
    });
    return total;
};

const reduceAndRandomizeArray = (array, total) => {
    // reduces an array lenght and radomizes it each time
    const length = count(array);

    if (length < total) {
        return array;
    }

    const arr = [];
    while (arr.length < total) {
        // const r = Math.floor(Math.random()*length) + 1;
        const r = Math.floor(Math.random() * length);
        if (arr.indexOf(r) === -1) arr.push(r);
    }

    const rv = [];
    Object.keys(arr).map((key, index) => {
        const selectedObj = array[arr[key]];
        rv.push(selectedObj);
        return null;
    });
    return rv;
};

const trimStringTolimit = (string, limit) => {
    const stringLength = string.length;
    if (limit > stringLength) {
        return string;
    }

    let str = string.substr(0, limit);
    str = `${str}...`;
    // console.log(str);
    return str;
};

const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
};

const valueInArray = (needle, heystack, field) => {
    // valueInArray(obj.id, formDataObj, "id")
    let rv = {
        result: false,
    };
    Object.keys(heystack).map((key, index) => {
        if (heystack[key][field] === needle) {
            rv.result = true;
            rv.index = index;
        }
    });
    // console.log(rv);
    return rv;
};

const convertToSingleLevelObj = (obj) => {
    /*
        This function will convert all nested key value pares ( nested to any level)
        into a single level pare

        //=== inpit obj
        const temp = {
            test: 'test',
            obj: {
                onemore: 'onemore',
                two: "two",
            },
            apple: 'apple juce',
            dog: {
                lab: "labrador"
            }
        }

        //== out put
        {
            "test": "test",
            "onemore": "onemore",
            "two": "two",
            "apple": "apple juce",
            "lab": "labrador"
        }

    */
    const rv = {};
    const scrapeThroughObj = (o, key = null) => {
        if (o && typeof o === "object") {
            Object.keys(o).map((key, index) => {
                scrapeThroughObj(o[key], key);
            });
        } else {
            rv[key] = o;
        }
    };
    scrapeThroughObj(obj);

    return rv;
};

const convertSingleLevelObjToArray = (obj) => {
    const rv = [];
    Object.keys(obj).map((key, index) => {
        rv.push(obj[key]);
    });

    return rv;
};

const getThisKeyFromAnyWhereNested = (obj, reqKey) => {
    // console.log(obj);
    // console.log(reqKey);

    const rv = {};
    const scrapeThroughObj = (o, key = null) => {
        if (o) {
            if (typeof o === "object") {
                Object.keys(o).map((key, index) => {
                    scrapeThroughObj(o[key], key);
                });
            } else {
                if (reqKey === key) {
                    rv[key] = o;
                }
            }
        } else {
            console.log("obj is null");
        }
    };
    scrapeThroughObj(obj);

    return rv;
};

const makeAnObjectFromThis = (data, fieldName) => {
    // usefull to make a drop down object from a server response
    const rv = {};
    Object.keys(data).map((key, index) => {
        const item = data[key];
        const value = item[fieldName];
        const id = item._id;
        rv[id] = data[key][fieldName];
    });
    return rv;
};

const convertFormObjToPostObj = (obj) => {
    const rv = {};
    Object.keys(obj).map((key, index) => {
        rv[key] = obj[key]["value"];
    });
    return rv;
};

const hasNumber = (myString) => {
    return /\d/.test(myString);
};

const hasLowerCase = (str) => {
    return /[a-z]/.test(str);
};

const passwordIsStrong = (string) => {
    let rv = false;
    if (string.length > 7) {
        if (hasNumber(string)) {
            rv = true;
        }
    }

    return rv;
};

const removeNonPritableValues = (str) => {
    // const str = this.removeNonPritableValues('äÄçÇéÉêPHP-MySQLöÖÐþúÚ');
    return str.replace(/[^\x20-\x7E]/g, "");
};

const removeArrayAtIndex = (obj, idx) => {
    const rv = [];
    Object.keys(obj).map((key, index) => {
        if (index !== idx) {
            rv.push(obj[key]);
        }
    });

    return rv;
};

const removeKeysFromObj = (removeKeys, sourceObj) => {
    /*
        //////==== caller:
        const tempFields = [
            'city_id',
            'city_name',
            'city_state',
            'studyingIn_id',
            'studyingIn_value'
        ]

        removeKeysFromObj(tempFields)
    */

    const rv = {};

    Object.keys(sourceObj).map((key, index) => {
        if (!inArray(key, removeKeys)) {
            rv[key] = sourceObj[key];
        }
    });

    return rv;
};

const convertDMYtoMDY = (date) => {
    const dateObj = date.split("-");
    const rvDate = `${dateObj[1]}-${dateObj[0]}-${dateObj[2]}`;
    return rvDate;
};

const authUser = (autoRedirect = false) => {
    let rv = false;
    const token = localStorage.getItem("x-auth-token");
    if (token) {
        /** authenticated use */
        rv = true;
    } else {
        /** inauthenticated user */
        if (autoRedirect) {
            window.location.href = "/";
        }
    }
    return rv;
};

const isOdd = (num) => {
    return num % 2;
};

const getUrls = () => {
    const url = [];
    url.push(window.location.href);
    url.push(window.location.hostname);
    url.push(window.location.pathname);
    url.push(window.location.protocol);
    url.push(window.location.assign);

    const rv = {
        pageName: url[2],
        data: url,
    };

    return rv;
};

const isActivePage = (string) => {
    const urlObj = getUrls();
    const pageName = urlObj.data[2];
    if (pageName == string) return true;
    return false;
};

const titleCase = (string) => {
    let sentence = string.toLowerCase().split(" ");
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }

    return sentence.join(" ");
};

const getValueUsingDotNotationPath = (needle, heystack) => {
    /** will get a value from an object using dot notation */
    // console.log(typeof needle, needle);
    const keySlpit = needle.split(".");
    let runninValue = heystack;
    Object.keys(keySlpit).map((key, index) => {
        if (runninValue[keySlpit[index]]) {
            const thisValue = runninValue[keySlpit[index]];
            runninValue = thisValue;
        } else {
            runninValue = "";
        }
    });
    return runninValue;
};

const inObj = (needle, heystack, path) => {
    /**
     * will search in an object and respond with found, and found obj (data)
     * You can use dot noation for path
     */
    let found = false;
    const foundObjs = [];

    Object.keys(heystack).map((key, index) => {
        const item = heystack[key];
        const value = getValueUsingDotNotationPath(path, item);
        // console.log(value, needle, item, path);
        if (value === needle) {
            found = true;
            foundObjs.push(item);
        }
    });

    const rv = {
        found: found,
        value: foundObjs,
    };

    return rv;
};

const inObjHold = (needle, heystack, path, returnItem = false) => {
    /**
     * will take dot notationed path and checks if the value is at any index in a given array
     *
     * == caller:
            inObj(peerData._id, "_id", peerData) // for direct boolion
            inObj(peerData._id, "_id", peerData, true) // for an obj with the item
     * returnItem = true: you will get the return value as an obj ( where .found is a boolean)
     * */
    // console.log(needle, path, heystack);
    const rv = { found: false };

    if (stateIsTrue(heystack)) {
        Object.keys(heystack).map((key, index) => {
            const item = heystack[key];
            const thisItemValue = getValueUsingDotNotationPath(path, item);

            if (thisItemValue === needle) {
                rv.found = true;
                rv.item = item;
            }
        });
    }

    if (returnItem) return rv;
    return rv.found;
};

const convertBoleanTo01 = (value) => {
    if (value) return 1;
    return 0;
};

const removeTheTrailingChrIf = (needle, string) => {
    /** removes the last charector if it is the needle */
    const strLength = string.length - 1;
    const lastChar = string[strLength];
    let newString = string;
    if (lastChar === needle) newString = string.substring(0, strLength);
    return newString;
};

const removeTheFirstChrIf = (needle, string) => {
    /** removes the first charector if it is the needle */
    const strLength = string.length;
    const char = string[0];
    let newString = string;
    if (char === needle) newString = string.substring(1, strLength);
    return newString;
};

const removeFirstAndLastCharIfItIs = (needle, string) => {
    /** removes both first and last charector if either has the needle char passed */
    let newString = "";
    newString = removeTheFirstChrIf(needle, string);
    newString = removeTheTrailingChrIf(needle, newString);
    return newString;
};

const removeTheTrailingChrRange = (needle, string) => {
    /**
     * this will remove the last charectors if they are similar to needle,
     * samel as removing trailing slash but with this you can remove / and space for eg
     *  ,-887,- // result = ,-887
     *  ,-88789 // result = ,-88789
     */
    const strLength = string.length;
    const needleLength = needle.length;
    const removeFrom = strLength - needleLength;

    const lastSelectedRange = string.substring(removeFrom, strLength);

    let newString = string;
    if (lastSelectedRange === needle)
        newString = string.substring(0, removeFrom);

    return newString;
};

const removeTheFirstChrRange = (needle, string) => {
    /**
     * this will remove the last charectors if they are similar to needle,
     * samel as removing trailing slash but with this you can remove / and space for eg
     *  ,-887,- // result = 887,-
     *  88789,- // result = 88789,-
     */
    const strLength = string.length;
    const needleLength = needle.length;

    const firstSelectedRange = string.substring(0, needleLength);

    let newString = string;
    if (firstSelectedRange === needle)
        newString = string.substring(needleLength, strLength);

    return newString;
};

const removeBothFistAndLastStringRangeIfTheyAre = (needle, string) => {
    /**
     * this will remove charectors from the string, from both start and end
     * if they are similar to the needle. eg: ",-" both ',-' will be removed
     * from start and end if they exist
     *  ,-887,- // result = 887
     *  ,-88789 // result = 88789
     */
    let newString = "";
    newString = removeTheFirstChrRange(needle, string);
    newString = removeTheTrailingChrRange(needle, newString);
    return newString;
};

export {
    whichWayToGoWithImage,
    makeStringShort,
    stateIsTrue,
    inArray,
    rowFromArray,
    deleterRowFromArray,
    urlFriendly,
    isObject,
    isEven,
    trimText,
    getDataFromServerResponse,
    reduceAndRandomizeArray,
    count,
    trimStringTolimit,
    isEmpty,
    makeAnObjectFromThis,
    getDataFromServerResponseNonData,
    convertFormObjToPostObj,
    convertToSingleLevelObj,
    getThisKeyFromAnyWhereNested,
    passwordIsStrong,
    removeNonPritableValues,
    removeArrayAtIndex,
    removeKeysFromObj,
    convertDMYtoMDY,
    valueInArray,
    convertSingleLevelObjToArray,
    authUser,
    isOdd,
    getUrls,
    isActivePage,
    titleCase,
    getValueUsingDotNotationPath,
    inObj,
    convertBoleanTo01,
    removeTheTrailingChrIf,
    removeTheFirstChrIf,
    removeTheTrailingChrRange,
    removeBothFistAndLastStringRangeIfTheyAre,
    removeFirstAndLastCharIfItIs,
};
