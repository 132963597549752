/** 
 * ::::: NEXT UPDATE ::::
 * UI update:
 *      make the user select the month first, so the date field can change accordingly to only the available dates
 * 
 * ::::: VERSION NOTE :::::
 * version 6
 * This comp got a major bugfix on 17th may 2020
 *  BUG: when the date was converted to js date object, the dateobject converter 
 *  returns one day lesser than the the passed value, looks like the obj in the 
 *  js date creater starts at 0, hence this component was providing the day as 
 *  one lesser than the users selection 
 * 
 * <DatePickerDropDown 
        required
        id="dob"
        placeHolder="Date of birth"
        forceValidation={this.state.validate_form}
        callBack={this.onFieldUdate.bind(this)}
        startYear="now" // you can give a year {2019} or pass the value now to take the current year
        endYear={10} // the total number of year to show from the start year ( note this is not a date it shoud be total years)
        runYearsBackwards // the display will be from now to previous years ( like dob )
    />

    ///////=== UPDATE NOTE
    Updated on 25th oct,
        There was major bug in validation, it was passing through though the error was false
    Last update on 16th may 2020
        Made some changes to the year customization, this is better and can display year in both directions 
        you can also put "now" to the start date to automate it to current year
 */

import React, { Component } from "react";
import { stateIsTrue } from "../HelperFunctions";
import { DropDownField, InputField } from "..";
import { getYearPluss } from "../DateHelper";

let formDataObj = {};

class DatePickerDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validate_form: false,
            forceValidation: false,
        };
    }

    componentWillReceiveProps(newProps) {
        // console.log(newProps);
        if (stateIsTrue(newProps.forceValidation)) {
            if (this.state.forceValidation !== newProps.forceValidation) {
                this.setState({ forceValidation: newProps.forceValidation });
            }
        }
    }

    getData() {
        let startYear = 2015;
        if (stateIsTrue(this.props.startYear)) {
            if (this.props.startYear == "now") {
                /** user has requested "now" */
                const nowYear = getYearPluss(0);
                startYear = nowYear;
            } else {
                /** user has specified a year */
                startYear = this.props.startYear;
            }
        }

        let totalyears = 70;
        if (stateIsTrue(this.props.endYear)) {
            totalyears = this.props.endYear;
            // totalyears = Math.abs(startYear - this.props.endYear);
        }

        const year = [];
        for (let index = 0; index < totalyears; index++) {
            const thisValue = this.props.runYearsBackwards
                ? startYear - index
                : startYear + index;

            const thisObj = {
                value: thisValue,
                id: thisValue,
            };
            // year.push(startYear-index);
            year.push(thisObj);
        }

        const month = [];
        for (let i = 0; i < 12; i++) {
            const value = i + 1;
            const thisMonth = {
                value: value,
                id: value,
            };
            // month.push(i+1)
            month.push(thisMonth);
        }

        const date = [];
        for (let ii = 1; ii < 32; ii++) {
            // const thisValue = ii + 1;
            const thisValue = ii;
            const thisDate = {
                value: thisValue,
                id: thisValue,
            };
            // date.push(ii+1);
            date.push(thisDate);
        }

        const rv = {};
        rv.month = month;
        rv.year = year;
        rv.date = date;

        return rv;
    }

    onFieldUdate(obj) {
        // console.log(obj);
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        };

        this.validateAndCallBack();
    }

    validateAndCallBack() {
        const convertToDate = () => {
            let date = formDataObj.date.value;
            let month = formDataObj.month.value;
            let year = formDataObj.year.value;

            if (formDataObj.date.value) date = formDataObj.date.value.value;
            if (formDataObj.month.value) month = formDataObj.month.value.value;
            if (formDataObj.year.value) year = formDataObj.year.value.value;
            // console.log(date);
            const rv = new Date(year, month - 1, date + 1);
            // console.log(rv);
            return rv;
        };

        if (Object.keys(formDataObj).length === 3) {
            let hasError = false;
            Object.keys(formDataObj).map((key, index) => {
                const error = formDataObj[key].error;
                if (error) {
                    hasError = true;
                }
            });

            const rv = {
                id: this.props.id,
                value: convertToDate(),
                error: hasError,
            };

            this.props.callBack(rv);
        }
    }

    renderForm() {
        return (
            <div className="date_wrapper">
                <div className="fiedset date">
                    <DropDownField
                        id="date"
                        placeHolder="Date"
                        options={this.getData().date}
                        skipNone
                        hidePlaceHolderIfValue
                        callBack={this.onFieldUdate.bind(this)}
                        forceValidation={this.state.forceValidation}
                        required
                    />
                </div>
                <div className="fiedset month">
                    <DropDownField
                        id="month"
                        placeHolder="Month"
                        options={this.getData().month}
                        skipNone
                        hidePlaceHolderIfValue
                        callBack={this.onFieldUdate.bind(this)}
                        forceValidation={this.state.forceValidation}
                        required
                    />
                </div>
                <div className="fiedset year">
                    <DropDownField
                        id="year"
                        placeHolder="Year"
                        options={this.getData().year}
                        skipNone
                        hidePlaceHolderIfValue
                        callBack={this.onFieldUdate.bind(this)}
                        forceValidation={this.state.forceValidation}
                        required
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="date_dropdown_wrapper">
                <div className="label_wrapper">{this.props.placeHolder}</div>
                {this.renderForm()}
            </div>
        );
    }
}

export { DatePickerDropDown };
