import React, { Component } from "react";

import "./support/typeWritterText.css";

class TypeWritterText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            speed: 200,
            textToRender: "",
            runningNumber1: 0,
            runningNumber2: 0,
            backSpaceRn2: 1,
            textObj: [],
            delayBetweenWords: 5,
            pause: true,
            pauseCount: 0,
        };
    }

    componentDidMount() {
        this.initValues();
        this.countdown = setInterval(this.ticker.bind(this), this.state.speed);
    }

    componentWillUnmount() {
        clearInterval(this.countdown);
    }

    initValues() {
        if (this.props.speed) this.setState({ speed: this.props.speed });
        // if(this.props.preText) this.setState({textToRender: this.props.preText});
        if (this.props.delayBetweenWords)
            this.setState({ delayBetweenWords: this.props.delayBetweenWords });

        const textObj = [];
        const dataText = this.props.dataText;
        Object.keys(dataText).map((key, index) => {
            const thisElem = [];
            Object.keys(dataText[key]).map((key2, index2) => {
                const char = dataText[key][key2];
                thisElem.push(char);
            });
            textObj.push(thisElem);
        });

        this.setState({ textObj: textObj });
    }

    ticker() {
        // this.setState({textToRender: 'ticked'})
        const rn1 = this.state.runningNumber1;
        const rn2 = this.state.runningNumber2;
        const bRn2 = this.state.backSpaceRn2;
        const obj = this.state.textObj;

        if (obj.length > rn1) {
            if (obj[rn1].length > rn2) {
                let newText = "";
                Object.keys(obj[rn1]).map((key, index) => {
                    if (obj[rn1].length > rn2) {
                        if (rn2 > index - 1) {
                            newText = `${newText}${obj[rn1][index]}`;
                        }
                    }
                });
                this.setState({ textToRender: newText });
                const newRn2 = rn2 + 1;
                this.setState({ runningNumber2: newRn2 });
            } else {
                //handle pause
                if (this.state.pauseCount > this.state.delayBetweenWords) {
                    this.setState({
                        pause: false,
                        pauseCount: 0,
                    });
                } else {
                    // console.log(this.state.pause);
                    const newPn = this.state.pauseCount + 1;
                    this.setState({
                        pauseCount: newPn,
                    });
                }

                //handle back space
                if (!this.state.pause) {
                    let newText = "";
                    const thisTextLength = obj[rn1].length;
                    const showLength = thisTextLength - bRn2;

                    Object.keys(obj[rn1]).map((key, index) => {
                        if (thisTextLength > showLength) {
                            if (showLength > index - 1) {
                                newText = `${newText}${obj[rn1][index]}`;
                            }
                        }
                    });

                    this.setState({ textToRender: newText });
                    const newbRn2 = bRn2 + 1;
                    this.setState({ backSpaceRn2: newbRn2 });

                    if (showLength <= 0) {
                        // console.log('one loop completed');
                        const newRn1 = rn1 + 1;
                        this.setState({
                            runningNumber1: newRn1,
                            runningNumber2: 0,
                            backSpaceRn2: 1,
                            pause: true,
                        });
                    }
                }
            }
        } else {
            // console.log('full loop completed');
            this.setState({
                runningNumber1: 0,
                runningNumber2: 0,
            });
        }
    }

    renderCursor() {
        return <div className="blink-caret"> | </div>;
    }

    render() {
        return (
            <div>
                <div className="typeWriterTextWrapper">
                    {this.props.preText} {this.state.textToRender}{" "}
                    {this.renderCursor()}
                </div>
            </div>
        );
    }
}

export { TypeWritterText };
