import React, { Component } from "react";
import { stateIsTrue } from "../HelperFunctions";
import { SpinnerLine } from "../Spinner";

class ImageUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            fileUplaoded: false,
            maxFileSize: 5,
            sourceFile: "",
            fileType: "",
            fileExtention: "",
            returnObj: {},
            fileValidated: false,
            validateFile: false,
            error: false,
            fileIsValid: false,
            renderFile: "",
            imageDimention: {},
            respondForceValidation: false,
            newFileName: "",
        };
    }

    //lifecycle events
    componentDidMount() {
        const newFileName = `${Math.floor(Date.now() / 1000)}${Math.round(
            Math.random() * 9999
        )}`;
        this.setState({ newFileName: newFileName });
    }

    componentDidUpdate() {
        if (this.state.respondForceValidation) {
            this.callBackToParent();
        }

        if (this.state.fileValidated) {
            this.handleValidationResponse();
            this.setState({ fileValidated: false });
        }

        if (this.state.validateFile) {
            this.validation();
            this.setState({ validateFile: false });
        }
    }

    //user events
    onChangeMethod(event) {
        if (event.target.files && event.target.files.length > 0) {
            const myFile = event.target.files[0];

            const fileNameText = this.state.newFileName;
            const fileExtention = myFile.type.split("/")[1];
            const fileName = `${fileNameText}.${fileExtention}`;
            const reNamedFile = new File([myFile], fileName, {
                type: myFile.type,
            });

            this.setState({
                sourceFile: reNamedFile,
                fileType: myFile.type,
                fileExtention: myFile.type.split("/")[1],
                fileUplaoded: true,
                validateFile: true,
            });
        }
    }

    componentWillReceiveProps(newProps) {
        // console.log(newProps);
        if (stateIsTrue(newProps.forceValidation)) {
            this.validateAndRespond();
        }
    }

    //helper
    init() {
        if (stateIsTrue(this.props.maxSize)) {
            this.setState({ maxFileSize: this.props.maxSize });
        }
    }

    callBackToParent() {
        const rv = this.state.returnObj;
        rv.id = this.props.id;
        rv.value = this.state.sourceFile;
        this.props.callBack(rv);
    }

    validateAndRespond() {
        if (stateIsTrue(this.props.required)) {
            if (!this.state.sourceFile) {
                const rv = {
                    error: true,
                    message: "This field is required",
                    id: this.props.id,
                };
                this.setState({
                    returnObj: rv,
                    error: true,
                    respondForceValidation: true,
                });
            }
        }

        // this.callBackToParent();
    }

    validation() {
        const myFile = this.state.sourceFile;
        const fileSizeBites = myFile.size;
        const maxSize = this.state.maxFileSize;
        const maxSizeKb = maxSize * 1000;
        const maxSizeBites = maxSizeKb * 1000;

        const rv = { error: false };

        if (fileSizeBites > maxSizeBites) {
            //file is large
            rv.error = true;
            rv.message = `file size should be less than ${maxSize} MB`;
        }

        const checkWidthHeight = () => {
            const validateDimention = (dimention) => {
                if (stateIsTrue(this.props.minWidth)) {
                    if (dimention.width < this.props.minWidth) {
                        rv.error = true;
                        rv.message = `file width should be atleast ${this.props.minWidth} px in width`;
                    }
                }

                this.setState({
                    returnObj: rv,
                    fileValidated: true,
                });
            };

            const callBackOnLoad = (img) => {
                const rv = {
                    width: img.width,
                    heigh: img.height,
                };
                validateDimention(rv);
                this.setState({ imageDimention: rv });
            };

            const renderFile = URL.createObjectURL(this.state.sourceFile);
            const img = new Image();
            img.onload = callBackOnLoad.bind(this, img);
            img.src = renderFile;

            this.setState({
                renderFile: renderFile,
            });
        };

        if (!rv.error) {
            checkWidthHeight();
        }

        this.setState({
            returnObj: rv,
            fileValidated: true,
        });
    }

    handleValidationResponse() {
        const rObj = this.state.returnObj;
        if (rObj.error) {
            this.setState({ error: true });
            this.callBackToParent();
        } else {
            this.setState({ fileIsValid: true });
            this.callBackToParent();
        }
    }

    resetState() {
        this.setState({
            value: "",
            fileUplaoded: false,
            sourceFile: "",
            fileType: "",
            fileExtention: "",
            returnObj: {},
            fileValidated: false,
            validateFile: false,
            error: false,
            fileIsValid: false,
            renderFile: "",
            imageDimention: {},
        });
    }

    renderInput() {
        if (this.state.error) {
            return (
                <div className="inputWrapper imageUploadInput  animated pulse">
                    <div className="error">{this.state.returnObj.message}</div>

                    <div
                        className="button"
                        onClick={this.resetState.bind(this)}
                    >
                        Try again
                    </div>
                </div>
            );
        }

        return (
            <div className="inputWrapper imageUploadInput">
                <input
                    type="file"
                    id={this.props.id}
                    onChange={this.onChangeMethod.bind(this)}
                    value={this.state.value}
                />
            </div>
        );
    }

    renderPreview() {
        return (
            <div className="inputWrapper imageUploadInput">
                <div className="image">
                    <img src={this.state.renderFile} alt="preview" />
                </div>
                <div className="imageDetails">
                    <div className="filename body">
                        {this.state.sourceFile.name}
                    </div>
                    <div className="dimentions body">
                        width: {this.state.imageDimention.width} px height:{" "}
                        {this.state.imageDimention.heigh} px
                    </div>
                </div>
            </div>
        );
    }

    renderPostUpload() {
        if (this.state.error) {
            return (
                <div className="input imageUploadInput">
                    <div className="error">{this.state.returnObj.message}</div>

                    <div
                        className="button"
                        onClick={this.resetState.bind(this)}
                    >
                        Try again
                    </div>
                </div>
            );
        }

        if (this.state.fileIsValid) {
            return this.renderPreview();
        }

        return (
            <div className="input imageUploadInput">
                <SpinnerLine />
            </div>
        );
    }

    renderDecide() {
        if (this.state.fileUplaoded) {
            return this.renderPostUpload();
        }

        return this.renderInput();
    }

    render() {
        return <div className="imageUploadWrapper">{this.renderDecide()}</div>;
    }
}

export { ImageUpload };
