import React from "react";

/** self user details */
const SelfDataContext = React.createContext();
const SelfDataProvider = SelfDataContext.Provider;
const SelfDataConsumer = SelfDataContext.Consumer;

/** peer list */
const PeerContext = React.createContext();
const PeerProvider = PeerContext.Provider;
const PeerConsumer = PeerContext.Consumer;

/** wallMessage list */
const MsgWallContext = React.createContext();
const MsgWallProvider = MsgWallContext.Provider;
const MsgWallConsumer = MsgWallContext.Consumer;

export {
    SelfDataProvider,
    SelfDataConsumer,
    PeerProvider,
    PeerConsumer,
    PeerContext,
    MsgWallProvider,
    MsgWallConsumer,
};
