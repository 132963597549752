/**
 *  // dependency on imageUpload
 * 
    <ImageUploadWithSubmit 
        uploadUrl={urls.proQuestionImageUpdload}
        callBack={this.onFieldUdate.bind(this)}
        id="cover"
        minWidth={1500}
        minHeight={550}
        label='Cover Image ( 1500px width and above )'
        required
        forceValidation={this.state.validate_form}
        schemaObj={schemaObj} // optional will just return the obj back to the callback func
    />
 */

import React, { Component } from "react";
import {
    ImageCrop,
    ImageUpload,
    FormDataValidation,
    stateIsTrue,
    getDataFromServerResponseNonData,
    SpinnerLine,
    getDataUploadFile,
} from "../";

import { urls } from "../../constant/urls";

let formDataObj = {};

class ImageUploadWithSubmit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showField: false,
            imageAdded: false,
            waitingForUplaodRes: false,
            file: "",
            gotUploadServerRes: false,
            uplaodServerRes: [],
            imageUplaodSuccess: false,
            imageUploadFailed: false,
            respondToParent: false,
        };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate() {
        if (this.state.gotUploadServerRes) {
            this.handleUploadResponse();
            this.setState({
                gotUploadServerRes: false,
                waitingForUplaodRes: false,
            });
        }

        if (this.state.respondToParent) {
            this.callBackParent();
            this.setState({ respondToParent: false });
        }
    }

    //user events
    onFieldUdate(obj) {
        console.log(obj);
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        };

        this.setState({
            imageAdded: true,
            file: obj.value,
        });
    }

    callBackParent() {
        // const result = this.state.uplaodServerRes.response.info.result;
        const result = this.state.uplaodServerRes.response;
        // console.log(this.props);
        const rv = {
            value: this.state.uplaodServerRes,
            error: true,
            result: result,
            id: this.props.id,
            schemaObj: this.props.schemaObj,
        };
        if (this.state.imageUplaodSuccess) rv.error = false;
        this.props.callBack(rv);
    }

    onUplaod() {
        this.setState({
            waitingForUplaodRes: true,
        });

        const postValue = {
            id: this.props.docId,
        };

        getDataUploadFile(this.state.file, this.props.uploadUrl, postValue)
            .then((result) => {
                this.setState({
                    gotUploadServerRes: true,
                    uplaodServerRes: result,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    gotUploadServerRes: true,
                });
            });
    }

    //helper
    handleUploadResponse() {
        const res = getDataFromServerResponseNonData(
            this.state.uplaodServerRes
        );
        if (res.success) {
            this.setState({
                imageUplaodSuccess: true,
                respondToParent: true,
            });
        } else {
            this.setState({
                imageUploadFailed: true,
            });
        }
    }

    init() {
        this.setState({ showField: true });
    }

    renderImageField() {
        const renderSubmitButton = () => {
            const getButtonContent = () => {
                if (stateIsTrue(this.props.buttonText)) {
                    return this.props.buttonText;
                }

                return "Upload Image";
            };
            return (
                <div
                    className="buttonRev uploadButton"
                    onClick={this.onUplaod.bind(this)}
                >
                    {getButtonContent()}
                </div>
            );
        };

        const renderWorking = () => {
            // console.log('called');
            return (
                <div className="submitProgress">
                    <div className="uploadButton buttonRev">
                        <SpinnerLine />
                    </div>
                    <div className="message">Upload in progress..</div>
                </div>
            );
        };

        const renderSuccess = () => {
            const renderSuccessMessage = () => {
                if (this.props.successMessage) {
                    return this.props.successMessage;
                } else {
                    return "Upload Completed";
                }
            };

            return (
                <div className="submitProgress">
                    <div className="uploadButton buttonRev">
                        {renderSuccessMessage()}
                    </div>
                </div>
            );
        };

        const renderFailed = () => {
            return (
                <div className="submitProgress errorWrapper">
                    <div className="error">Oops1</div>
                    <div className="message">
                        Something went wrong, looks like an internal server
                        error
                    </div>
                </div>
            );
        };

        const renderDecide = () => {
            if (this.state.imageUploadFailed) return renderFailed();
            if (this.state.imageUplaodSuccess) return renderSuccess();
            if (this.state.waitingForUplaodRes) return renderWorking();
            if (this.state.imageAdded) return renderSubmitButton();
        };

        return (
            <div className="fiedset row card">
                <div className="label mb20">{this.props.label}</div>
                <ImageUpload
                    callBack={this.onFieldUdate.bind(this)}
                    id={this.props.id}
                    minWidth={this.props.minWidth}
                    minHeight={this.props.minHeight}
                    required
                    forceValidation={this.state.validate_form}
                    maxSize={this.props.maxSize}
                />

                {renderDecide()}
            </div>
        );
    }

    renderDecide() {
        if (this.state.showField) return this.renderImageField();

        return <SpinnerLine />;
    }

    render() {
        // console.log(this.state);
        return (
            <div className="imageUplaodWithSubmitWrapper">
                {this.renderDecide()}
            </div>
        );
    }
}

export { ImageUploadWithSubmit };
