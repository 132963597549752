import React, { Component } from "react";

import { InputField } from "../";
import { stateIsTrue } from "../HelperFunctions";
import "../../../style/css/formStyle.css";

const remove = require("./support/remove.png");

let formDataObj = {};

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: 3,
            field: "name",
            options: [],
            showOptions: false,
            inputResponse: [],
            selectedOptionValue: "",
            switchInput: false,
        };
    }

    componentDidMount() {
        if (stateIsTrue(this.props.field)) {
            this.setState({ field: this.props.field });
        }
    }

    componentWillReceiveProps(newProps) {
        if (stateIsTrue(newProps.forceValidation)) {
            this.setState({ updateInput: true });
        }
    }

    onFieldUdate(obj) {
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        };

        this.setState({ updateInput: false });
        this.setState({ inputResponse: obj });

        if (!obj.value) {
            this.setState({ showOptions: false });
            this.props.callBack(obj);
        }

        this.fetchForData(obj);
    }

    fetchForData(obj) {
        if (!obj.value) return null;

        const res = this.matchString(obj.value);
        if (res.length > 0) {
            this.setState({ options: res });
            this.setState({ showOptions: true });
        }
    }

    matchString(value) {
        const rv = [];

        const checkInArray = (v) => {
            let runninNo = 0;
            const obj = this.props.options;
            Object.keys(obj).map((key, index) => {
                const stringInObj = obj[key]["name"].toLowerCase();
                const valueOpt = v.toLowerCase();
                if (stringInObj.includes(valueOpt)) {
                    if (runninNo < 10) {
                        rv.push(obj[key]);
                        runninNo++;
                    } else {
                        return null;
                    }
                }
            });
        };

        checkInArray(value);

        return rv;
    }

    onClickOption(elem) {
        const { error } = this.state.inputResponse;
        const obj = {
            value: elem,
            id: this.props.id,
            error: error,
        };
        const selectedValue = elem[this.state.field];
        this.setState({ selectedOptionValue: selectedValue });
        this.setState({ showOptions: false });
        this.setState({ switchInput: true });
        this.props.callBack(obj);
    }

    onProxyInputClick() {
        this.setState({ switchInput: false });
    }

    renderOptions() {
        let options = this.state.options;

        if (this.state.showOptions) {
            return (
                <div className="option_wrapper">
                    <div className="options">
                        {Object.keys(options).map((key, index) => {
                            return (
                                <div
                                    className="option"
                                    key={index}
                                    onClick={this.onClickOption.bind(
                                        this,
                                        options[key]
                                    )}
                                >
                                    {options[key][this.state.field]}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return null;
    }

    renderInput() {
        if (this.state.switchInput) {
            return (
                <div className="input_field input_field_proxy">
                    <div className="text">{this.state.selectedOptionValue}</div>
                    <div
                        className="autocomplete_icon"
                        onClick={this.onProxyInputClick.bind(this)}
                    >
                        <img src={remove} alt="Edit" />
                    </div>
                </div>
            );
        }

        return (
            <InputField
                id={this.props.id}
                placeHolder={this.props.placeHolder}
                required
                forceValidation={this.state.updateInput}
                callBack={this.onFieldUdate.bind(this)}
                defaultValue=""
            />
        );
    }

    render() {
        return (
            <div className="auto_complete_wrapper">
                {this.renderInput()}
                {this.renderOptions()}
            </div>
        );
    }
}

const optionObj = ["somde", "sdsdsd"];

export { AutoComplete };
