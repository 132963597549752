import React from "react";
import {
    stateIsTrue,
    getDateFromMongoRes,
    getTimeFromMongoseRes,
    secondsToTime,
    getValueUsingDotNotationPath,
    convertToSingleLevelObj,
} from "./";

//<RenderBodyLib body={item.body} />
const RenderBodyLib = ({ body }) => {
    // console.log(body);
    const getClassNames = (type) => {
        let rv = "";
        switch (type) {
            case "title":
                rv = "view_title title_header body_title";
                break;
            case "body":
                rv = "view_body body";
                break;

            default:
                break;
        }

        return rv;
    };

    return Object.keys(body).map((key, index) => {
        return (
            <div key={index} className={getClassNames(body[key].type)}>
                {body[key].value}
            </div>
        );
    });
};

const renderHelperConvertValue = (presetObj, string) => {
    const type = presetObj.type;
    // console.log(type);
    const handleBoolean = (string) => {
        let rv = "No";
        if (string) rv = "Yes";
        return rv;
    };

    const handleImage = (string) => {
        return <img src={string} alt="string" />;
    };

    const handleArray = (array) => {
        // console.log(array);
        return array[0];
    };

    const handleObject = (obj) => {
        // return renderHelperRenderObj(obj, presetObj);
        return renderHelperRenderObjFlat(obj, presetObj);
        // return 'it is object'
    };

    const handleJson = (obj) => {
        const tObj = JSON.parse(obj);
        return renderHelperRenderObj(tObj, presetObj);
        // return 'it is object'
    };

    const handleSecondsToTime = (obj) => {
        if (!obj && obj < 1) return "";
        const sObj = secondsToTime(obj);
        const rv = `${sObj.h} h : ${sObj.m} m & ${sObj.s} s`;
        // console.log(sObj);
        return rv;
    };

    const handleNestedCollection = (obj) => {
        const { label, nestedCollectionField, type } = presetObj;
        if (!nestedCollectionField) {
            console.log(
                `${label} is set to ${type}, key nestedCollectionField is required you should specify the field to user ( dot notationed )`
            );
            return obj;
        }

        let rv = "";
        const getSeparator = (string) => {
            if (string) {
                if (presetObj.separator) return presetObj.separator;
                return ",";
            }

            return "";
        };
        Object.keys(obj).map((key, index) => {
            const item = obj[key];
            if (typeof nestedCollectionField == "object") {
                let nnValue = "";
                Object.keys(nestedCollectionField).map((key, index) => {
                    const nestnrestitem = nestedCollectionField[key];
                    nnValue = `${nnValue} ${getValueUsingDotNotationPath(
                        nestnrestitem,
                        item
                    )}`;
                });
                rv = `${rv}${getSeparator(rv)} ${nnValue}`;
            } else {
                rv = `${rv}${getSeparator(rv)} ${getValueUsingDotNotationPath(
                    nestedCollectionField,
                    item
                )}`;
            }
        });

        return rv;
    };

    let rv = string;
    switch (type) {
        case "date":
            rv = getDateFromMongoRes(string);
            break;
        case "time":
            rv = getTimeFromMongoseRes(string);
            break;
        case "boolean":
            rv = handleBoolean(string);
            break;
        case "image":
            rv = handleImage(string);
            break;
        case "array":
            rv = handleArray(string);
            break;
        case "object":
            rv = handleObject(string);
            break;
        case "secondsToTime":
            rv = handleSecondsToTime(string);
            break;
        case "nestedCollection":
            rv = handleNestedCollection(string);
            break;
        case "json":
            rv = handleJson(string);
            break;

        default:
            break;
    }

    return rv;
};

const renderHelperRenderObj = (data, presetObj = []) => {
    const renderField = (fieldValue, key, index) => {
        const processValue = (value) => {
            const fieldObjHasKey = (fieldObj) => {
                const rv = { refExists: false };
                if (!fieldObj) return rv;

                Object.keys(fieldObj).map((keyFo, index) => {
                    const keyInFieldObj = fieldObj[keyFo]["key"];
                    // console.log(keyInFieldObj, key);
                    const keyPathObj = keyInFieldObj.split(".");
                    const parentKey = keyPathObj[0];
                    if (parentKey === key) {
                        rv.obj = fieldObj[keyFo];
                        rv.refExists = true;
                    }
                });
                return rv;
            };

            if (stateIsTrue(presetObj)) {
                /** presetObj is the the key that is
                 * predefined in the main object as to how to handle this field */
                const nestedPresetObj = presetObj.presetObj;
                // console.log(nestedPresetObj[0]);
                if (nestedPresetObj) {
                    const refObjRes = fieldObjHasKey(nestedPresetObj);
                    console.log(refObjRes, " tdggggg");
                    if (refObjRes.refExists) {
                        /** there is a preset handler for this field */
                        return renderHelperConvertValue(refObjRes.obj, value);
                    }
                    // return renderHelperConvertValue(nestedPresetObj, value);
                }
            }

            return value;
        };

        return (
            <div key={index} className="row">
                <div className="label labelNested">{key}</div>
                <div className="value valueNested">
                    {processValue(fieldValue)}
                </div>
            </div>
        );
    };

    const renderDoc = (docObj, index) => {
        return (
            <div key={index} className="item">
                {Object.keys(docObj).map((key, index2) => {
                    const thisField = docObj[key];
                    if (typeof thisField === "object")
                        renderDoc(thisField, index2);
                    return renderField(thisField, key, index2);
                })}
            </div>
        );
    };

    return (
        <div className="nestedRowWrapper">
            {Object.keys(data).map((key, index) => {
                const thisDoc = data[key];
                return renderDoc(thisDoc, index);
            })}
        </div>
    );
};

const renderHelperRenderObjFlat = (data, presetObj = []) => {
    const flatObj = convertToSingleLevelObj(data);

    const renderItem = (item, key, index) => {
        const processValue = (value) => {
            const fieldObjHasKey = (fieldObj) => {
                const rv = { refExists: false };
                if (!fieldObj) return rv;

                Object.keys(fieldObj).map((keyFo, index) => {
                    const keyInFieldObj = fieldObj[keyFo]["key"];
                    // console.log(keyInFieldObj, key);
                    const keyPathObj = keyInFieldObj.split(".");
                    const parentKey = keyPathObj[0];
                    if (parentKey === key) {
                        rv.obj = fieldObj[keyFo];
                        rv.refExists = true;
                    }
                });
                return rv;
            };

            if (stateIsTrue(presetObj)) {
                /** presetObj is the the key that is
                 * predefined in the main object as to how to handle this field */
                const nestedPresetObj = presetObj.presetObj;
                // console.log(nestedPresetObj[0]);
                if (nestedPresetObj) {
                    const refObjRes = fieldObjHasKey(nestedPresetObj);
                    console.log(refObjRes, " tdggggg");
                    if (refObjRes.refExists) {
                        /** there is a preset handler for this field */
                        return renderHelperConvertValue(refObjRes.obj, value);
                    }
                    // return renderHelperConvertValue(nestedPresetObj, value);
                }
            }

            return value;
        };

        return (
            <div key={index} className="item">
                <div className="label labelNested">{key}</div>
                <div className="value valueNested">{processValue(item)}</div>
            </div>
        );
    };

    return (
        <div className="row">
            {Object.keys(flatObj).map((key, index) => {
                return renderItem(flatObj[key], key, index);
            })}
        </div>
    );
};

export {
    RenderBodyLib,
    renderHelperRenderObj,
    renderHelperConvertValue,
    renderHelperRenderObjFlat,
};
