/*  
    LAST UPDATED ON: 29th jan
    this is the new version with dot notation for nestedarrows:
    1) As default this will show all the fields
    
    ////== sample field object
    <DocumentView 
        docUrl={this.props.viewUrl}
        docId={this.state.showViewId}
        fieldObj={this.props.viewFields}
        skipFields={this.props.viewSkipFields}
        shareDocData={this.receiveDocData.bind(this)} // optional
    />

    // It will show all the fields by default, 
    if you want to skip a fields mention the field name in "viewSkipFieldsObj" obj
    // The preset objects are the same as collection and downloads

    shareDocData: 
        this will return the document when it is loaded in the view (documentView). So that you can 
        work on the data for other resons

    const teamObj = [
        { label: "Is Team Lead", key: "teamLead", type:"boolean" },
    ]
    
    const viewObj = [
        { label: "City", key: "city.0.name" },
        { label: "Studying In", key: "studyingIn.0.value" },
        { label: "area", key: "area" },
        { label: "DOB", type:"date", key: "dob" },
        { label: "Time", type:"time", key: "dateTime" },
        { label: "Total Minutes", key: "loginTimeTotal.secondsTotal", type:"secondsToTime" },
        { label: "mobile Validation", type:"boolean", key: "mobileValidation" },
        { label: "Is batchImport", type:"boolean", key: "batchImport" },
        { label: "Created At", type:"date", key: "createdAt" },
        { label: "Team", type:"object", key: "team", presetObj:teamObj},
        
        { label: "category", key: "category", type: 'nestedCollection', nestedCollectionField: 'name',  separator: " ",},
        { label: "winners", key: "winners", type: 'nestedCollection', nestedCollectionField: ['firstName','lastName']}
        // show one field from a newsted collection
    ]
    
    const viewSkipFieldsObj = [
        "isPublished",
        "agreed",
        "__v"
    ]

    const viewPageContextualMenu = [
        {
            label: "Super user - enable/disable",
            callBack: this.onEnableDisableSuperUser.bind(this)
        }
    ]
    

*/

import React, { Component } from "react";
import {
    PostDataDirect,
    Pagination,
    stateIsTrue,
    getDataFromServerResponse,
    getThisKeyFromAnyWhereNested,
    getDateFromMongoRes,
    getValueUsingDotNotationPath,
    renderHelperConvertValue,
} from ".";
import { SpinnerLine } from "./Spinner";
import "../../style/css/documentView.css";

class DocumentView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doc_value: [],
            docNotFound: false,
        };
    }

    componentWillMount() {
        const postObj = {
            id: this.props.docId,
        };
        this.getDoc(postObj);
    }

    /** lifecycleEvents */
    getDoc(postObjParam) {
        let postObj = postObjParam;
        if (this.props.postObj)
            postObj = { ...postObjParam, ...this.props.postObj };

        PostDataDirect(this.props.docUrl, postObj, this.onLoadDoc.bind(this));
    }

    onLoadDoc(res) {
        const result = getDataFromServerResponse(res);
        if (result.success) {
            const data = result.data;
            if (this.props.shareDocData) this.props.shareDocData(data);
        }
        this.setState({ doc_value: res });
    }

    /** render */
    renderContextualMenu() {
        if (stateIsTrue(this.props.contextualMenu)) {
            const { contextualMenu } = this.props;
            const { doc_value } = this.state;

            const renderItem = (item, index) => {
                return (
                    <div
                        key={index}
                        className="card item button"
                        onClick={() => item.callBack(doc_value)}
                    >
                        {item.label}
                    </div>
                );
            };

            return (
                <div className="contextualMenuWrapper">
                    {Object.keys(contextualMenu).map((key, index) => {
                        return renderItem(contextualMenu[key], index);
                    })}
                </div>
            );
        }

        return null;
    }

    renderContent() {
        const viewFieldObj = this.props.fieldObj;

        const renderField = (field, key, doc, index) => {
            const fieldObjHasKey = () => {
                const { fieldObj } = this.props;
                const rv = { refExists: false };
                if (!fieldObj) return rv;

                Object.keys(fieldObj).map((keyFo, index) => {
                    const keyInFieldObj = fieldObj[keyFo]["key"];
                    // console.log(keyInFieldObj, key);
                    const keyPathObj = keyInFieldObj.split(".");
                    const parentKey = keyPathObj[0];
                    if (parentKey === key) {
                        rv.obj = fieldObj[keyFo];
                        rv.refExists = true;
                    }
                });
                return rv;
            };

            const getValueForPreset = (presetObj, value) => {
                const keyPath = presetObj["key"];
                if (!keyPath) {
                    console.log("key is missing in", presetObj);
                    return null;
                }
                // console.log(keyPath,value);
                let retValue = getValueUsingDotNotationPath(keyPath, doc);

                // if(presetObj['type']) retValue = convertValue(presetObj,retValue);
                // console.log(presetObj['type']);
                if (presetObj["type"])
                    retValue = renderHelperConvertValue(presetObj, retValue);

                return retValue;
            };

            const getValue = () => {
                const refObjRes = fieldObjHasKey();
                if (refObjRes.refExists) {
                    /** there is a preset handler for this field */
                    return getValueForPreset(refObjRes.obj, field);
                }

                if (typeof field !== "object") return field;

                return JSON.stringify(field);
            };

            const getLabel = (key) => {
                const refObjRes = fieldObjHasKey();
                if (refObjRes.refExists) {
                    /** there is a preset handler for this field */
                    return refObjRes.obj["label"];
                }

                return key;
            };

            const inSkip = (key) => {
                const { skipFields } = this.props;
                let rv = false;
                if (skipFields) {
                    Object.keys(skipFields).map((key2, index) => {
                        const skipElem = skipFields[key2];
                        if (skipElem === key) rv = true;
                    });
                }

                return rv;
            };

            if (inSkip(key)) return null;

            return (
                <div key={index} className="item">
                    <div className="label"> {getLabel(key)}: </div>
                    <div className="value"> {getValue()}: </div>
                </div>
            );
        };

        const res = getDataFromServerResponse(this.state.doc_value);

        if (res.success) {
            let data = res.data[0];
            if (!data) data = res.data;

            return (
                <div className="card view_wrapper">
                    {Object.keys(data).map((key, index) => {
                        const field = data[key];
                        return renderField(field, key, data, index);
                    })}
                </div>
            );
        }

        return (
            <div className="card view_wrapper">
                <SpinnerLine />
            </div>
        );
    }

    render() {
        return (
            <div className="documentViewWrapper">
                {this.renderContextualMenu()}
                {this.renderContent()}
            </div>
        );
    }
}

export { DocumentView };
