/*
    renderList() {
        return (
            <CollectionList 
                listUrl={urls.userList}
                totalCountUrl={urls.userTotal}
                objList={this.getListObj()}

                postObj={getPostObj()}

                viewUrl={urls.userView}
                viewFields={viewFieldObj}
                viewSkipFields={viewSkipFieldsObj}
                
                search
                searchUrl={urls.searchUserEmail}
                searchLabel="Search by email"
                callBackOnView={this.onCollectionViewCallBack.bind(this)}

                customField
                customFieldLabel="ds" // optional
                customFieldValue="Enable/Disable"
                customFieldCallBack={this.onEnableDisable.bind(this)}

                download
                downloadObjHandler={listObj}

                shareDocData={this.receiveDocData.bind(this)}

                edit
                editSubmitUrl={urls.proChallengeUpdate}
                editSkipFieldsObj={editSkipFieldsObj}
                editRenderSpecObj={editRenderSpecObj}

                delete // if the delete should be rejected from the server ( send response.reject:true, response.info:"message to show")
                deleteUrl

                pageTitleField="name" // this is shown in view and edit page, dot notation handled

                filters={filters}

                // renderAdditionalData={functionName}
            />
        )
    }


    /////=====  Descriotion
    listUrl // the url to get the collections ( list )
    totalCountUrl // the url to get the total count
    objList // the object with fields to show in the list, refernce is bellow
    viewUrl // the url that will be used to get the document when clicked on read more
    viewFields // the object that is used to display the fields in the view
    
    delet //  OPTIONAL - a delete option will be shown
    // you can also handle delete rejection from the server,
    if for some reason the doc should not be deleted, the the server should respond with these details:
    1) response should be success
    2) response.reject = true 
        (returnObj.response.reject = true)
    2) response.info = 'your message' 
        (returnObj.response.info = 'This challenge has purchases, hence cannot delete it')
    
    deleteUrl // dependecy for delete - the URL to delete the row
    search: true will show a search bar
    searchUrl: url to send the search string
    searchLabel: place holder of the search bar
    callBackOnview: optional// callbak if you want to do something when readmore or view is on
    customField: this cal be used to crete a custom call back, like change a field to active or disable.
        If the user clicks on it, customFieldCallBack function will be called with the item in the param
    postObj: any post value that should be send to the server apart from the pagination info

    shareDocData: 
        this will return the document when it is loaded in the view (documentView). So that you can 
        work on the data for other resons


    /////======= sample of the field objs to pass
    // this is for the list, this is inside the class so each field has an onclick option
     getListObj() {
        const listObj = [
            { label: "Date", type:"date", key: "dateTime" },
            { label: "Project", key: "projectName" },
            { label: "Name", key: ['userData.0.firstName','userData.0.lastName'], separator: ' ' },
            { label: "log in/out", key: "direction" },
            { label: "Total Minutes", key: "loginTimeTotal.secondsTotal", type:"secondsToTime" },
            { label: "Time", type:"time", key: "dateTime" },
            { label: "Locatoin", 
                key: ["location.coords.latitude", "location.coords.longitude"],
                separator: ', ',
                onClick: this.onLocation.bind(this)
            }
        ]

        return listObj;
    }

*   //==== View

    const viewObj = [
        { label: "City", key: "city.0.name" },
        { label: "Studying In", key: "studyingIn.0.value" },
        { label: "area", key: "area" },
        { label: "DOB", type:"date", key: "dob" },
        { label: "Time", type:"time", key: "dateTime" },
        { label: "Total Minutes", key: "loginTimeTotal.secondsTotal", type:"secondsToTime" },
        { label: "mobile Validation", type:"boolean", key: "mobileValidation" },
        { label: "Is batchImport", type:"boolean", key: "batchImport" },
        { label: "Created At", type:"date", key: "createdAt" },
        { label: "Team", type:"object", key: "team", presetObj:teamObj},
        
        { label: "category", key: "category", type: 'nestedCollection', nestedCollectionField: 'name'},
        { label: "winners", key: "winners", type: 'nestedCollection', nestedCollectionField: ['firstName','lastName']}
        // show one field from a newsted collection
    ]

*   //=== Edit    

    const editSkipFieldsObj = [
        '__v',
        'createdAt',
        '_id',
        'name2',
        'active'
    ]

    const manualyHandlePushData = (value) => {
        return { data: value };
    };

    const editRenderSpecObj = [
        { key: "onLine", type:"boolean", tip:'if true, the challenge will be shown in the app' },
        { key: "open", type:"boolean", tip:'If true, the challenge will be open to play' },
        { key: "closed", type:"boolean", tip:'if true, the challenge will close'  },
        { key: "showDates", type:"boolean", tip:'If true, no dates will be shown in the thumbnail' },
        { key: "numberOfQuestions", type:"int" },
        { key: "cost", type:"int" },
        { key: "startDate", type:"date", startYear:getYearPluss(1), endYear:getYearMinus(1) },
        { key: "endDate", type:"date", startYear:getYearPluss(1), endYear:getYearMinus(1)  },
        { key: "content", type: "textarea", force: true},
        { 
            key: "content",
            type: "nestedObjEdit",
            fetchUrl: urls.proRulesView,
            submitUrl: urls.proRulesUpdate,
            staticInput: true,
            field: "data",
            postKey: "content", // optional
            nestPath: "content",
            pushDataHandler: manualyHandlePushData, // optional
            maxLength: 2000,
            force: true, // if the field is not in the incoming data schema, still this will be shown
        },
        {
            key: "image",
            type: "image",
            label: "Upload an Image",
            maxWidth: 1500,
            maxHeight: 1500,
            UploadUrl: urls.proQuestionImageUpdload,
            docUpdateUrl: urls.proQuestionImageUpdateName,
            displayImagePath: imagePaths.proQuestionImage,
            deleteFileUrl: urls.proQuestionImageDelete,
            force: true, // if the field is not in the incoming data schema, still this will be shown
        },
    ]


    /==== filters
    const filters = [
        {
            label:"User Email",
            key:'email'
        },
        {
            label:"User Phone",
            key:'phone'
        }
    ]

*/

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import {
    PostDataDirect,
    Pagination,
    stateIsTrue,
    getDataFromServerResponse,
    DocumentView,
    InputField,
    DownloadToCsv,
    DropDownField,
    getThisKeyFromAnyWhereNested,
    getDateFromMongoRes,
    getValueUsingDotNotationPath,
    renderHelperConvertValue,
    removeFirstAndLastCharIfItIs,
    EditDocument,
} from ".";
import { SpinnerLine } from "./Spinner";

import "../../style/css/collectionList.css";
import { urls, localUrls } from "../constant/urls";
import { PhoneField } from "./form/PhoneField";
import {
    convertFormObjToPostObj,
    removeTheTrailingChrRange,
    removeTheFirstChrRange,
    removeBothFistAndLastStringRangeIfTheyAre,
} from "./HelperFunctions";

let formDataObj = {};
let filterPayloadObj = {};

class CollectionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list_data: [],
            noDataToShow: false,
            initialShowingLastIndex: 20,
            showingLastIndex: 20,
            showingStartIndex: 0,
            deleteId: "",
            deletedRejected: false,
            deletedRejectedMessage: "",
            refreshPagination: 1,
            showView: false,
            showViewId: "",
            requestReload: false,
            showEdit: false,
            selectedDocId: "",
            selectedItem: "",

            showFilters: false,
            filterPayload: {},
            freshRealodCollectoin: false,
        };
    }

    /** lifecycle */
    componentDidMount() {
        let postObj = {};
        postObj.skip = 0;
        postObj.limit = this.state.showingLastIndex;
        this.getList(postObj);
    }

    componentDidUpdate() {
        if (this.state.freshRealodCollectoin) {
            this.handleFreshReloadCollection();
            this.setState({ freshRealodCollectoin: false });
        }

        if (stateIsTrue(this.state.requestReload)) {
            if (stateIsTrue(this.state.initialShowingLastIndex)) {
                // const postObj = {
                //     "offset": this.state.showingStartIndex,
                //     "total": this.state.initialShowingLastIndex
                // };

                let postObj = {};
                // if(stateIsTrue(this.props.postObj)) postObj = this.props.postObj;
                postObj.skip = this.state.showingStartIndex;
                postObj.limit = this.state.initialShowingLastIndex;

                this.getList(postObj);
                this.setState({
                    requestReload: false,
                    // showingLastIndex: this.state.showingStartIndex + this.state.initialShowingLastIndex
                });
            }
        }
    }

    /** user events */
    onPagination(obj) {
        // const postObj = {
        //     "offset": obj.offset,
        //     "total": obj.limit
        // };

        let postObj = {};
        // if(stateIsTrue(this.props.postObj)) postObj = this.props.postObj;
        postObj.skip = obj.offset;
        postObj.limit = obj.limit;

        this.getList(postObj);

        const updatedTotal = obj.offset + obj.limit;
        this.setState({ showingLastIndex: updatedTotal });
        this.setState({ showingStartIndex: obj.offset });
    }

    onFieldUdate(obj) {
        // console.log(obj);
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        };

        if (
            obj.id === "limit" &&
            obj.value &&
            obj.value.id !== this.state.initialShowingLastIndex
        ) {
            const newCount = this.state.refreshPagination + 1;
            this.setState({
                initialShowingLastIndex: obj.value.id,
                requestReload: true,
                refreshPagination: newCount,
            });
        }
    }

    onFilterFieldUdate(obj) {
        filterPayloadObj[obj.id] = obj.value;
        // console.log(filterPayloadObj);
    }

    onLoadList(res) {
        if (this.props.debug) console.log(res);
        const data = getDataFromServerResponse(res);
        if (!data.success) this.setState({ noDataToShow: true });
        this.setState({ list_data: res });
    }

    onLoadSearch(res) {
        this.setState({ list_data: res });
    }

    onDeleteButton(item) {
        this.setState({ deleteId: item["_id"] });
    }

    onDeleteNo() {
        this.setState({ deleteId: "-" });
    }

    onDeleteYes(item) {
        const postObj = {
            id: item["_id"],
        };
        PostDataDirect(
            this.props.deleteUrl,
            postObj,
            this.afterDeleteCallBack.bind(this)
        );
    }

    onReadMore(item) {
        this.setState({
            showView: true,
            showViewId: item["_id"],
            selectedDocId: item["_id"],
            selectedItem: item,
        });
        if (stateIsTrue(this.props.callBackOnView)) {
            this.props.callBackOnView(item, true);
        }
    }

    onCloseView() {
        this.setState({ showView: false, showViewId: "" });
        if (stateIsTrue(this.props.callBackOnView)) {
            this.props.callBackOnView(null, false);
        }
    }

    onCloseEdit() {
        this.setState({ showEdit: false, selectedDocId: "" });
        if (stateIsTrue(this.props.callBackOnEdit)) {
            this.props.callBackOnEdit(null, false);
        }
    }

    onSearch() {
        console.log(this);
        console.log(formDataObj);
        console.log(
            'for search the key the backend is looking for should be called "search"'
        );

        const postObj = {
            string: formDataObj.search.value,
        };
        this.getSearch(postObj);
    }

    onCustsomField(item) {
        this.props.customFieldCallBack(item);
    }

    onRange() {
        const formObj = convertFormObjToPostObj(formDataObj);

        if (formObj.from && formObj.to) {
            const postObj = {
                skip: parseInt(formObj.from),
                limit: formObj.to - formObj.from,
            };
            // console.log(postObj);
            this.getList(postObj);
        }
    }

    onEdit(item) {
        this.setState({
            showEdit: true,
            selectedDocId: item._id,
            selectedItem: item,
        });
    }

    onShowFilters() {
        this.setState({ showFilters: true });
    }

    onCloseFilter() {
        this.setState({ showFilters: false });
    }

    onFilterSubmit() {
        if (stateIsTrue(filterPayloadObj)) {
            this.setState({
                filterPayload: { filters: filterPayloadObj },
                showFilters: false,
                freshRealodCollectoin: true,
            });
        }
    }

    onRemoveFilters() {
        filterPayloadObj = {};
        this.setState({
            filterPayload: {},
            freshRealodCollectoin: true,
        });
    }

    /** helper */
    handleFreshReloadCollection() {
        const postObj = {};
        postObj.skip = 0;
        postObj.limit = 50;
        this.getList(postObj);
    }

    getList(postObjParam) {
        const postObj = {
            ...postObjParam,
            ...this.props.postObj,
            ...this.state.filterPayload,
        };

        PostDataDirect(this.props.listUrl, postObj, this.onLoadList.bind(this));
    }

    getSearch(postObj) {
        PostDataDirect(
            this.props.searchUrl,
            postObj,
            this.onLoadSearch.bind(this)
        );
    }

    afterDeleteCallBack(res) {
        // console.log(res);
        if (res.response.reject) {
            /** delete was rejected */
            this.setState({
                deletedRejected: true,
                deletedRejectedMessage: res.response.info,
            });
        } else {
            this.setState({ deleteId: "" });
            const postObj = {
                skip: this.state.showingStartIndex,
                limit:
                    this.state.showingLastIndex - this.state.showingStartIndex,
            };
            this.getList(postObj);

            //upaate the prop to refresh pagination total
            const newCount = this.state.refreshPagination + 1;
            this.setState({ refreshPagination: newCount });
        }
    }

    hideInView() {
        let rv = {};
        if (this.state.showView) {
            rv = {
                display: "none",
            };
        }

        return rv;
    }

    //renders
    renderSearch() {
        if (!stateIsTrue(this.props.search)) {
            return null;
        }

        const getPlaceHolder = () => {
            if (stateIsTrue(this.props.searchLabel)) {
                return this.props.searchLabel;
            }

            return "Search";
        };

        return (
            <div className="searchbar_wrapper" style={this.hideInView()}>
                <div className="fiedset card">
                    <InputField
                        id="search"
                        placeHolder={getPlaceHolder()}
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                    />
                    <div className="button" onClick={this.onSearch.bind(this)}>
                        go
                    </div>
                </div>
            </div>
        );
    }

    renderDelete(item) {
        if (stateIsTrue(this.props.delete)) {
            if (this.state.deleteId === item["_id"]) {
                if (this.state.deletedRejected) {
                    return (
                        <div className="cell delete_wrapper">
                            <div className="confirm">
                                <div className="message">
                                    {this.state.deletedRejectedMessage}
                                </div>
                            </div>
                        </div>
                    );
                }

                return (
                    <div className="cell delete_wrapper">
                        <div className="confirm">
                            <div className="message">
                                Are you sure you want to delete:
                            </div>
                            <div className="action">
                                <div
                                    className="button no"
                                    onClick={this.onDeleteNo.bind(this)}
                                >
                                    No
                                </div>
                                <div
                                    className="button yes"
                                    onClick={this.onDeleteYes.bind(this, item)}
                                >
                                    Yes
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                <div
                    className="cell delete_wrapper"
                    onClick={this.onDeleteButton.bind(this, item)}
                >
                    Delete
                </div>
            );
        }
        return null;
    }

    renderList() {
        const renderItem = (item, index) => {
            const getIndex = () => {
                const rv = index + this.state.showingStartIndex + 1;
                return rv;
            };

            const renderReadmore = () => {
                if (stateIsTrue(this.props.viewUrl)) {
                    return (
                        <div className="cell">
                            <div
                                className="button"
                                onClick={this.onReadMore.bind(this, item)}
                            >
                                Read More
                            </div>
                        </div>
                    );
                }
            };

            const renderEdit = () => {
                if (stateIsTrue(this.props.edit)) {
                    return (
                        <div className="cell">
                            <div
                                className="button"
                                onClick={this.onEdit.bind(this, item)}
                            >
                                Edit
                            </div>
                        </div>
                    );
                }
            };

            const renderCustomField = () => {
                if (stateIsTrue(this.props.customField)) {
                    return (
                        <div className="cell">
                            <div
                                className="button"
                                onClick={this.onCustsomField.bind(this, item)}
                            >
                                {this.props.customFieldValue}
                            </div>
                        </div>
                    );
                }
            };

            const getValueFromData = (keyPath, doc, elem) => {
                const assembleFromMultipleRows = () => {
                    let rnValue = "";
                    let separatorChar = ",";
                    if (stateIsTrue(elem.separator))
                        separatorChar = elem.separator;

                    Object.keys(keyPath).map((key, index) => {
                        const pathItem = keyPath[key];
                        const thisPathValue = getValueUsingDotNotationPath(
                            pathItem,
                            doc
                        );
                        rnValue = `${rnValue}${separatorChar}${thisPathValue}`;
                    });

                    rnValue = removeBothFistAndLastStringRangeIfTheyAre(
                        separatorChar,
                        rnValue
                    );
                    return rnValue;
                };

                if (typeof keyPath === "object") {
                    return assembleFromMultipleRows();
                } else {
                    return getValueUsingDotNotationPath(keyPath, doc);
                }
            };

            const renderValue = (doc, elem) => {
                const keyPath = elem["key"];

                if (!keyPath) {
                    console.log("key is missing in", elem);
                    return null;
                }

                let value = getValueFromData(keyPath, doc, elem);

                if (elem["type"]) value = renderHelperConvertValue(elem, value);

                return value;
            };

            return (
                <div key={index} className="item">
                    <div className="cell srno">{getIndex()}</div>

                    {Object.keys(this.props.objList).map((key, index) => {
                        const elem = this.props.objList[key];
                        const fieldName = elem.key;
                        // console.log(elem, fieldName,'rachdd coll');

                        if (elem.onClick) {
                            // console.log('inside onclik');
                            return (
                                <div
                                    key={index}
                                    className={`cell ${fieldName}_wrapper button`}
                                    onClick={() => elem.onClick(item)}
                                >
                                    {renderValue(item, elem)}
                                </div>
                            );
                        }

                        return (
                            <div
                                key={index}
                                className={`cell ${fieldName}_wrapper`}
                            >
                                {renderValue(item, elem)}
                            </div>
                        );
                    })}

                    {renderReadmore()}
                    {renderEdit()}

                    {renderCustomField()}

                    {this.renderDelete(item)}
                </div>
            );
        };

        const renderDelete = () => {
            if (stateIsTrue(this.props.delete)) {
                return (
                    <div className="cell">
                        <div className="">Delete</div>
                    </div>
                );
            }
            return null;
        };

        const renderLabel = () => {
            const renderReadmore = () => {
                if (stateIsTrue(this.props.viewUrl)) {
                    return (
                        <div className="cell">
                            <div className="">Read More</div>
                        </div>
                    );
                }
            };

            const renderEdit = () => {
                if (stateIsTrue(this.props.edit)) {
                    return (
                        <div className="cell">
                            <div className="">Edit</div>
                        </div>
                    );
                }
            };

            const renderCustom = () => {
                if (stateIsTrue(this.props.customField)) {
                    return (
                        <div className="cell">
                            <div className="">
                                {this.props.customFieldLabel}
                            </div>
                        </div>
                    );
                }
            };

            return (
                <div className="item label">
                    <div className="cell">srno</div>
                    {Object.keys(this.props.objList).map((key, index) => {
                        const elem = this.props.objList[key];
                        const labelName = elem.label;
                        const fieldName = elem.field;
                        return (
                            <div
                                key={index}
                                className={`cell ${fieldName}_wrapper`}
                            >
                                {labelName}
                            </div>
                        );
                    })}

                    {renderReadmore()}
                    {renderEdit()}
                    {renderCustom()}
                    {renderDelete()}
                </div>
            );
        };

        const data = getDataFromServerResponse(this.state.list_data);

        if (data.success) {
            return (
                <div className="card list_wrapper ">
                    {renderLabel()}
                    {Object.keys(data.data).map((key, index) => {
                        return renderItem(data.data[key], index);
                    })}
                </div>
            );
        }
    }

    renderEditComp() {
        return (
            <div className="content_wrapper">
                <div className="header card">
                    <div
                        className="block button"
                        onClick={this.onCloseEdit.bind(this)}
                    >
                        Back
                    </div>
                    {this.renderPageTitle()}
                </div>
                <EditDocument
                    fetchUrl={this.props.viewUrl}
                    selectedDocId={this.state.selectedDocId}
                    submitUrl={this.props.viewUrl}
                    editSkipFieldsObj={this.props.editSkipFieldsObj}
                    editRenderSpecObj={this.props.editRenderSpecObj}
                    editSubmitUrl={this.props.editSubmitUrl}
                />
            </div>
        );
    }

    renderPageTitle() {
        if (this.props.pageTitleField) {
            return (
                <div className="block">
                    <div className="">{this.props.pageTitleField}</div>
                    <div className="title">
                        {/* {this.state.selectedItem[this.props.pageTitleField]} */}
                        {getValueUsingDotNotationPath(
                            this.props.pageTitleField,
                            this.state.selectedItem
                        )}
                    </div>
                </div>
            );
        }
    }

    renderFilters() {
        const { filters } = this.props;
        // console.log(filters);
        const renderFilterForms = () => {
            const renderItem = (item, index) => {
                return (
                    <div key={index} className="fiedset card">
                        <InputField
                            id={item.key}
                            placeHolder={item.label}
                            forceValidation={this.state.validate_form}
                            callBack={this.onFilterFieldUdate.bind(this)}
                        />
                    </div>
                );
            };

            return (
                <div className="thumb_wrapper">
                    {Object.keys(filters).map((key, index) => {
                        return renderItem(filters[key], index);
                    })}
                </div>
            );
        };

        return (
            <div className="content_wrapper">
                <div className="header card">
                    <div
                        className="block button"
                        onClick={this.onCloseFilter.bind(this)}
                    >
                        Back
                    </div>
                    {this.renderPageTitle()}
                </div>

                {renderFilterForms()}
                <div className="card">
                    <div
                        className="button"
                        onClick={this.onFilterSubmit.bind(this)}
                    >
                        go
                    </div>
                </div>
            </div>
        );
    }

    renderContent() {
        if (this.state.showFilters) return this.renderFilters();
        if (this.state.showEdit) return this.renderEditComp();

        if (
            stateIsTrue(this.state.showView) &&
            stateIsTrue(this.state.showViewId)
        ) {
            return (
                <div className="content_wrapper">
                    <div className="header card">
                        <div
                            className="block button"
                            onClick={this.onCloseView.bind(this)}
                        >
                            Back
                        </div>
                        {this.renderPageTitle()}
                    </div>
                    <DocumentView
                        docUrl={this.props.viewUrl}
                        docId={this.state.showViewId}
                        fieldObj={this.props.viewFields}
                        skipFields={this.props.viewSkipFields}
                        contextualMenu={this.props.contextualMenu}
                        postObj={this.props.postObj}
                        shareDocData={
                            this.props.shareDocData
                                ? this.props.shareDocData
                                : false
                        }
                    />
                </div>
            );
        }

        if (this.state.noDataToShow) {
            return <div className="card content_wrapper">No Data to show</div>;
        }

        if (stateIsTrue(this.state.list_data)) {
            return <div className="content_wrapper">{this.renderList()}</div>;
        }

        return (
            <div className="content_wrapper">
                <SpinnerLine />
            </div>
        );
    }

    renderDownload() {
        if (
            stateIsTrue(this.props.download) &&
            !stateIsTrue(this.state.showView)
        ) {
            return (
                <DownloadToCsv
                    data={this.state.list_data}
                    objectHandler={this.props.downloadObjHandler}
                    // downloadValueConverterObj = {this.props.downloadValueConverterObj}
                />
            );
        }

        return null;
    }

    renderAdditionalFilters() {
        // const limitOptions = [20, 50, 100, 200, 400, 600, 800, 1000];
        const limitOptions = [
            { id: 20, value: 20 },
            { id: 50, value: 50 },
            { id: 100, value: 100 },
            { id: 200, value: 200 },
            { id: 400, value: 400 },
            { id: 600, value: 600 },
            { id: 800, value: 800 },
            { id: 1000, value: 1000 },
            // { id: 1500, value: 1500 },
        ];

        // console.log(limitOptions);
        return (
            <div className="additional_filters">
                <div className="totalWrapper">
                    <DropDownField
                        id="limit"
                        options={limitOptions}
                        callBack={this.onFieldUdate.bind(this)}
                        placeHolder="Total"
                        disableSlidingLabel
                    />
                </div>

                <div className="rangeWrapper">
                    <div className="fiedset">
                        <PhoneField
                            id="from"
                            placeHolder="From"
                            required
                            forceValidation={this.state.validate_form}
                            callBack={this.onFieldUdate.bind(this)}
                            maxLength={1}
                            errorMessage="Not a number"
                            donotShowlabel
                        />
                    </div>

                    <div className="fiedset">
                        <PhoneField
                            id="to"
                            placeHolder="to"
                            required
                            forceValidation={this.state.validate_form}
                            callBack={this.onFieldUdate.bind(this)}
                            maxLength={1}
                            errorMessage="Not a number"
                            donotShowlabel
                        />
                    </div>
                    <div
                        className="smallButton"
                        onClick={this.onRange.bind(this)}
                    >
                        GO
                    </div>
                </div>
            </div>
        );
    }

    renderAdditionalData() {
        if (stateIsTrue(this.props.renderAdditionalData)) {
            return (
                <div className="additionalData">
                    {this.props.renderAdditionalData()}
                </div>
            );
        }

        return null;
    }

    renderPagination() {
        if (stateIsTrue(this.props.totalCountUrl)) {
            if (this.state.showEdit) return null;
            if (this.state.showFilters) return null;

            let postObj = {};
            if (stateIsTrue(this.props.postObj)) postObj = this.props.postObj;

            return (
                <div className="list_menu_holder" style={this.hideInView()}>
                    <Pagination
                        countUrl={this.props.totalCountUrl}
                        postObj={postObj}
                        startTotal={this.state.initialShowingLastIndex}
                        callBack={this.onPagination.bind(this)}
                        refreshCount={this.state.refreshPagination}
                    />
                    {this.renderAdditionalFilters()}
                </div>
            );
        }
    }

    renderFiltersTrigger() {
        if (!this.props.filters) return null;

        if (!this.state.showFilters) {
            if (stateIsTrue(this.state.filterPayload)) {
                const { filters } = this.state.filterPayload;

                const renderFilterDetails = () => {
                    const renderItem = (item, index) => {
                        // console.log(item);
                        return (
                            <div key={index} className="item">
                                {item}
                            </div>
                        );
                    };

                    return (
                        <div className="thumb_wrapper">
                            {Object.keys(filters).map((key, index) => {
                                return renderItem(filters[key], index);
                            })}
                        </div>
                    );
                };

                return (
                    <div className="card rangeWrapper">
                        <div className="label">Showing results for:</div>
                        <div className="details">{renderFilterDetails()}</div>
                        <div
                            className="smallButton"
                            onClick={this.onRemoveFilters.bind(this)}
                        >
                            Remove Filters
                        </div>
                    </div>
                );
            }

            return (
                <div className="button" onClick={this.onShowFilters.bind(this)}>
                    Filters
                </div>
            );
        }
    }

    render() {
        return (
            <div className="collectinListCompWrapper">
                <div className="topbar">
                    {this.renderPagination()}
                    {this.renderFiltersTrigger()}
                    {this.renderSearch()}
                    {this.renderDownload()}
                    {this.renderAdditionalData()}
                </div>
                {this.renderContent()}
            </div>
        );
    }
}

export { CollectionList };
