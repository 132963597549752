/**
 * 
 * 
 *  <EditDocument 
        fetchUrl={this.props.viewUrl}
        selectedDocId={this.state.selectedDocId}
        submitUrl={this.props.viewUrl}
        editSkipFieldsObj={this.props.editSkipFieldsObj}
        editRenderSpecObj={this.props.editRenderSpecObj}
        editSubmitUrl={this.props.editSubmitUrl}
    />
 * 
    ==============================================================================================
    NOTE:: Set the force prop to true, to make the edit show the field incase the data from server
    doesent have the fild already:
    ==============================================================================================
    Image edit notes:
    1   fields names sent to the server for delete is: "id" and "fieldName", 
        so you should eb looking for these fields and handle it there, 
        you will have to delete the fiel and update the doc on the server ( have saved a snippet for this)
    2) if the image is blank, it will show the upload option
        if the image is not blank it will show the remove option
    3) deleteFieldUrl: the comp will submit "id" and "fieldName" to the server


    ===========================================
    
    const editSkipFieldsObj = [
        '__v',
        'createdAt',
        '_id',
        'name2',
        'active'
    ]

    const manualyHandlePushData = (value) => {
        return { data: value };
    };

    const editRenderSpecObj = [
        { key: "onLine", type:"boolean", tip:'if true, the challenge will be shown in the app' },
        { key: "open", type:"boolean", tip:'If true, the challenge will be open to play' },
        { key: "closed", type:"boolean", tip:'if true, the challenge will close'  },
        { key: "showDates", type:"boolean", tip:'If true, no dates will be shown in the thumbnail' },
        { key: "numberOfQuestions", type:"int" },
        { key: "cost", type:"int" },
        { key: "startDate", type:"date", startYear:getYearPluss(1), endYear:getYearMinus(1) },
        { key: "endDate", type:"date", startYear:getYearPluss(1), endYear:getYearMinus(1)  },
        { key: "content", type: "textarea", },
        { 
            key: "content",
            type: "nestedObjEdit",
            fetchUrl: urls.proRulesView,
            submitUrl: urls.proRulesUpdate,
            staticInput: true,
            field: "data",
            postKey: "content", // optional
            nestPath: "content",
            pushDataHandler: manualyHandlePushData, // optional
        },
        {
            key: "image",
            type: "image",
            label: "Upload an Image",
            maxWidth: 1500,
            maxHeight: 1500,
            UploadUrl: urls.proQuestionImageUpdload,
            docUpdateUrl: urls.proQuestionImageUpdateName,
            displayImagePath: imagePaths.proQuestionImage,
            deleteFileUrl: urls.deleteFilmImage,
            force: true, // if the field is not in the incoming data schema, still this will be shown
        },
    ]
 */

import React, { Component } from "react";
import {
    PhoneField,
    InputField,
    EmailField,
    TextAreaField,
    Checkbox,
    DropDownField,
    FormDataValidation,
    stateIsTrue,
    SpinnerLine,
    Spinner,
    PostDataDirect,
    getDataFromServerResponse,
    getDataFromServerResponseNonData,
    convertFormObjToPostObj,
    DatePickerDropDown,
    getYearMinus,
    ImageUploadWithSubmit,
    inArray,
    inObj,
    NestedObjectHandler,
    renderHelperConvertValue,
} from "./";
import "../../style/css/EditDocument.css";
import { getDateFromMongoResFull, getDateFromMongoRes } from "./DateHelper";
import { urls } from "../constant/urls";

let formDataObj = {};

class EditDocument extends Component {
    constructor(props) {
        super(props);
        this.attendedFields = [];
        this.state = {
            validate_form: false,
            submitingData: false,
            success: false,
            failed: false,

            fetchUrl: "",
            selectedDocId: "",
            submitUrl: "",
            editSkipFieldsObj: [],
            editRenderSpecObj: [],
            editSubmitUrl: "",

            fetchData: false,
            existingData: {},
            existingDataIsReady: false,
            imageUploaded: {},
            updateImage: false,
            imageRemoved: false, //temp
            reset: false,
        };
    }

    /** lifecycle events */
    componentDidMount() {
        this.init();
    }

    componentDidUpdate() {
        if (this.state.reset) {
            this.init();
            this.setState({ reset: false });
        }

        if (this.state.validate_form) {
            this.handleFormSubmit();
            this.setState({ validate_form: false });
        }

        if (this.state.fetchData) {
            this.fetchData();
            this.setState({ fetchData: false });
        }
    }
    /** user events */
    onFieldUdate(obj) {
        formDataObj[obj.id] = {
            value: obj.value,
            error: obj.error,
        };
    }

    afterFileUploadHold(res) {
        const { fileName } = res.result;
        const { docUpdateUrl } = this.props.editRenderSpecObj;

        const { id, schemaObj } = res;

        const uploadedImageObj = this.state.imageUploaded;
        uploadedImageObj[id] = {
            id: id,
            dbUpdated: false,
        };
        this.setState({ imageUploaded: uploadedImageObj });

        const postObj = {
            id: this.state.selectedDocId,
            image: fileName,
        };

        const url = schemaObj.docUpdateUrl;
        const callBackFucntion = (res) => {
            const result = getDataFromServerResponse(res);
            if (result.success) {
                const data = result.data;
                uploadedImageObj[id].dbUpdated = true;
            }
        };
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    }

    afterFileUpload(res) {
        this.reset();
    }

    OnSubmit() {
        this.setState({
            validate_form: true,
            submitingData: true,
        });
    }

    onRemoveImage(obj) {
        const { item, key, schemaObj } = obj;
        const imageName = typeof item === "object" ? item[0] : item;

        const url = schemaObj.deleteFileUrl;
        const postObj = {
            id: this.state.selectedDocId,
            fieldName: key,
        };

        const callBackFucntion = (res) => {
            const result = getDataFromServerResponseNonData(res);
            if (result.success) {
                const data = result.data;
                this.reset();
                // window.location.reload();
            }
        };
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    }

    /** helper functions */
    reset() {
        this.setState({
            validate_form: false,
            submitingData: false,
            success: false,
            failed: false,

            fetchUrl: "",
            selectedDocId: "",
            submitUrl: "",
            editSkipFieldsObj: [],
            editRenderSpecObj: [],
            editSubmitUrl: "",

            fetchData: false,
            existingData: {},
            existingDataIsReady: false,
            imageUploaded: {},
            updateImage: false,
            imageRemoved: false, //temp

            reset: true, // this is custom
        });
    }

    init() {
        const {
            fetchUrl,
            selectedDocId,
            submitUrl,
            editSkipFieldsObj,
            editRenderSpecObj,
            editSubmitUrl,
        } = this.props;
        if (!fetchUrl)
            return console.log("Edit-EditDocument: cannot find fetchUrl");
        if (!selectedDocId)
            return console.log("Edit-EditDocument: cannot find selectedDocId");
        if (!submitUrl)
            return console.log("Edit-EditDocument: cannot find submitUrl");
        if (!editSubmitUrl)
            return console.log("Edit-EditDocument: cannot find editSubmitUrl");

        // console.log(fetchUrl, selectedDocId, submitUrl);

        this.setState({
            fetchUrl: fetchUrl,
            selectedDocId: selectedDocId,
            fetchData: true,
            editSkipFieldsObj: editSkipFieldsObj,
            editRenderSpecObj: editRenderSpecObj,
            editSubmitUrl: editSubmitUrl,
        });
    }

    fetchData() {
        const url = this.state.fetchUrl;
        const postObj = { id: this.state.selectedDocId };

        const callBackFucntion = (res) => {
            const result = getDataFromServerResponse(res);
            if (result.success) {
                const data = result.data;
                this.setState({
                    existingData: data,
                    existingDataIsReady: true,
                });
            }
        };
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    }

    handleFormSubmit() {
        const hasError = FormDataValidation(formDataObj);

        if (!hasError) {
            /** validation passed, submit form */
            this.submitForm();
        } else {
            /** Validation failed, nothing to do,
             * the input field will show erriors by default */
            this.setState({ submitingData: false });
        }
    }

    submitForm() {
        const url = this.state.editSubmitUrl;
        const postObj = convertFormObjToPostObj(formDataObj);
        postObj.id = this.state.selectedDocId;

        const callBackFucntion = (res) => {
            const result = getDataFromServerResponseNonData(res);
            if (result.success) {
                this.setState({ success: true });
            } else {
                console.log(res);
                this.setState({ failed: true });
            }

            this.setState({ submitingData: false });
        };
        // console.log(postObj);
        PostDataDirect(url, postObj, callBackFucntion.bind(this));
    }

    /** renders */
    renderFormHold() {
        const renderSubmit = () => {
            if (this.state.submitingData) {
                return (
                    <div className="submit_wrapper">
                        <SpinnerLine />
                    </div>
                );
            }

            return (
                <div className="submit_wrapper">
                    <div className="button" onClick={this.OnSubmit.bind(this)}>
                        Submit
                    </div>
                </div>
            );
        };

        return (
            <div className="form_holder">
                <div className="fiedset">
                    <InputField
                        id="name"
                        placeHolder="Name"
                        required
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                    />
                </div>

                <div className="fiedset">{renderSubmit()}</div>
            </div>
        );
    }

    renderForm() {
        const { existingData, editRenderSpecObj } = this.state;

        const renderSubmit = () => {
            if (this.state.submitingData) {
                return (
                    <div className="submit_wrapper">
                        <SpinnerLine />
                    </div>
                );
            }

            return (
                <div className="fiedset submit_wrapper">
                    <div className="button" onClick={this.OnSubmit.bind(this)}>
                        Submit
                    </div>
                </div>
            );
        };

        const tobeSkipped = (key) => {
            const { editSkipFieldsObj } = this.state;
            if (editSkipFieldsObj)
                if (inArray(key, editSkipFieldsObj)) return true;
            return false;
        };

        const renderInput = (item, key) => {
            const defaultValue = typeof item === "object" ? "" : item;
            return (
                <div className="fiedset">
                    <div className="editFieldLabel">{key}</div>
                    <InputField
                        id={key}
                        placeHolder={key}
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                        defaultValue={defaultValue}
                        donotShowlabel
                    />
                </div>
            );
        };

        const renderTextArea = (key, item) => {
            return (
                <div className="fiedset">
                    <div className="editFieldLabel">{key}</div>
                    <TextAreaField
                        id={key}
                        placeHolder={key}
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                        defaultValue={item}
                        donotShowlabel
                    />
                </div>
            );
        };

        const renderBoolean = (key, item) => {
            const renderTip = () => {
                if (editRenderSpecObj) {
                    // console.log(key, editRenderSpecObj);
                    const objRes = inObj(key, editRenderSpecObj, "key");

                    if (objRes.found) {
                        const tipValue = objRes.value[0].tip;
                        if (tipValue) {
                            return <div className="tip">{tipValue}</div>;
                        }
                    }
                }

                return null;
            };

            return (
                <div className="fiedset">
                    <div className="editFieldLabel">{key}</div>
                    <Checkbox
                        callBack={this.onFieldUdate.bind(this)}
                        forceValidation={this.state.validate_form}
                        id={key}
                        // label={key}
                        setPresetTrue={item}
                    />
                    {renderTip()}
                </div>
            );
        };

        const renderInt = (key, item) => {
            return (
                <div className="fiedset intset">
                    <div className="editFieldLabel">{key}</div>
                    <PhoneField
                        id={key}
                        placeHolder={key}
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                        defaultValue={item}
                        donotShowlabel
                        maxLength={1}
                    />
                </div>
            );
        };

        const renderDate = (key, item) => {
            const objRes = inObj(key, editRenderSpecObj, "key");

            const getStartYear = () => {
                if (objRes.found) {
                    const thisValue = objRes.value[0].startYear;
                    if (thisValue) {
                        return thisValue;
                    }
                }
                return "";
            };

            const getEndYear = () => {
                if (objRes.found) {
                    const thisValue = objRes.value[0].endYear;
                    if (thisValue) {
                        return thisValue;
                    }
                }
                return "";
            };

            return (
                <div className="fiedset intset">
                    <div className="editFieldLabel">{key}</div>
                    <DatePickerDropDown
                        id={key}
                        placeHolder={key}
                        forceValidation={this.state.validate_form}
                        callBack={this.onFieldUdate.bind(this)}
                        edit
                        defaultValue={getDateFromMongoRes(item)}
                        donotShowlabel
                        required
                        startYear={getStartYear()}
                        endYear={getEndYear()}
                    />
                </div>
            );
        };

        const renderNestedObjEdit = (key, item, schemaObj) => {
            return (
                <div className="fiedset stackItRow ">
                    <div className="editFieldLabel">{key}</div>
                    <NestedObjectHandler
                        staticInput={
                            schemaObj.staticInput
                                ? schemaObj.staticInput
                                : false
                        }
                        fetchUrl={schemaObj.fetchUrl}
                        searchUrl={schemaObj.searchUrl}
                        submitUrl={schemaObj.submitUrl}
                        id={this.state.selectedDocId}
                        nestPath={schemaObj.key}
                        field={schemaObj.field ? schemaObj.field : "name"}
                        pushDataHandler={schemaObj.pushDataHandler}
                        postKey={
                            schemaObj.postKey
                                ? schemaObj.postKey
                                : schemaObj.key
                        }
                        maxLength={schemaObj.maxLength}
                    />
                </div>
            );
        };

        const renderDecideImage = (schemaObj, item, key) => {
            const renderPreview = () => {
                const postObj = {
                    item: item,
                    key: key,
                    schemaObj: schemaObj,
                };
                return (
                    <div className="fiedset imageDisplayWrapper">
                        <div className="editFieldLabel">{key}</div>
                        <div className="imageUplaodWithSubmitWrapper">
                            <div className="fiedset">
                                <img
                                    src={`${schemaObj.displayImagePath}/${item}`}
                                    alt="Image"
                                />
                            </div>
                            <div className="fiedset">
                                <div
                                    className="buttonRev"
                                    onClick={this.onRemoveImage.bind(
                                        this,
                                        postObj
                                    )}
                                >
                                    Remove Image
                                </div>
                            </div>
                        </div>
                    </div>
                );
            };
            const renderImageUplaod = () => {
                if (this.state.imageUploaded[key]) {
                    /** image uploaded has happened, but the file name is not
                     * yet entered into the db */
                    if (this.state.imageUploaded[key].dbUpdated) {
                        return <div className="h1">Image uploaded!!</div>;
                    } else if (this.state.imageUploaded[key].deleted) {
                        return (
                            <div className="imageDisplayWrapper">
                                Image removed
                            </div>
                        );
                    } else {
                        return (
                            <div className="imageDisplayWrapper">
                                Image uploaded
                            </div>
                        );
                    }
                }

                return (
                    <div className="imageDisplayWrapper">
                        <div className="editFieldLabel">{key}</div>
                        <ImageUploadWithSubmit
                            callBack={this.afterFileUpload.bind(this)}
                            id={key}
                            label={this.props.label}
                            required
                            forceValidation={this.state.validate_form}
                            uploadUrl={schemaObj.UploadUrl}
                            schemaObj={schemaObj}
                            docId={this.state.selectedDocId}
                        />
                    </div>
                );
            };

            if (stateIsTrue(item)) return renderPreview();
            return renderImageUplaod();
        };

        const renderValue = (item, key) => {
            /** handle type */
            const { editRenderSpecObj } = this.state;
            if (editRenderSpecObj) {
                const editRenderSpecObjRes = inObj(
                    key,
                    editRenderSpecObj,
                    "key"
                );
                if (editRenderSpecObjRes.found) {
                    const editRenderSpecObjItem = editRenderSpecObjRes.value;
                    const type = editRenderSpecObjItem[0].type;
                    if (type == "textarea") return renderTextArea(key, item);
                    if (type == "boolean") return renderBoolean(key, item);
                    if (type == "int") return renderInt(key, item);
                    if (type == "date") return renderDate(key, item);
                    if (type == "nestedObjEdit") {
                        return renderNestedObjEdit(
                            key,
                            item,
                            editRenderSpecObjItem[0]
                        );
                    }
                    if (type == "image") {
                        return (
                            <div className="fiedset">
                                {renderDecideImage(
                                    editRenderSpecObjItem[0],
                                    item,
                                    key
                                )}
                            </div>
                        );
                    }
                    /** if force = true ( we have to show the option though it is missing in the doc) */
                    if (type == "input") return renderInput(item, key);
                }
            }

            if (typeof item === "object") return null;
            /** end handle type */

            return renderInput(item, key);
        };

        const renderItem = (item, key, index) => {
            if (tobeSkipped(key)) return null;

            return (
                <div key={index} className="row">
                    {renderValue(item, key)}
                </div>
            );
        };

        const renderNewItem = (key, index) => {
            /** this is used to render fields that is not in the existing
             * data set and the same field is set to force ( show if empty )
             * in the render obj schema */
            if (tobeSkipped(key)) return null;
            return renderValue({}, key);
        };

        const renderNonAttendedFields = () => {
            /** if the field is not in the data base, it will not be shown in the edit.
             * hence if the field is specified in the render helper obj and the same is
             * set to force, we will render the same here */
            if (!editRenderSpecObj) return null;

            return (
                <div className="row newFields">
                    {Object.keys(editRenderSpecObj).map((key, index) => {
                        const item = editRenderSpecObj[key];
                        const specObjKey = item["key"];
                        if (!inArray(specObjKey, this.attendedFields)) {
                            /** required (force) field is not in the received data */
                            return renderNewItem(specObjKey, index);
                        }
                    })}
                </div>
            );
        };

        return (
            <div className="thumb_wrapper">
                {Object.keys(existingData).map((key, index) => {
                    this.attendedFields.push(key);
                    const item = existingData[key];
                    return renderItem(item, key, index);
                })}
                {renderNonAttendedFields()}
                {/* {renderImageUplaod()} */}
                {renderSubmit()}
            </div>
        );
    }

    renderSuccess() {
        if (!this.state.success) return null;

        return <div className="message">Successfully updated</div>;
    }

    renderFailed() {
        if (!this.state.failed) return null;

        return (
            <div className="message failed">
                Oops sorry :( something went wrong, we could not process your
                request.
            </div>
        );
    }

    renderDecide() {
        if (this.state.success) return this.renderSuccess();
        if (this.state.failed) return this.renderFailed();
        if (this.state.existingDataIsReady) return this.renderForm();

        return <Spinner />;
    }

    render() {
        return <div className="card editDocWrapper">{this.renderDecide()}</div>;
    }
}

export { EditDocument };
